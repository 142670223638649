<div class="selected-address-list-container">
    <table class="email-address-list-table table table-striped">
        <tbody>
            <tr class="addressRow" *ngFor="let email of selectedEmailAddressList | sort:'asc':'emailAddress'">
                <td class="address">{{email.emailAddress}}</td>
                <td class="delete"><i title="Delete" class="bi bi-trash" [ngClass]="{'disabled': isDisabled }"
                        [attr.disabled]="isDisabled ? 'disabled' : null" (click)="removeEmail(email)"></i></td>
            </tr>
            <tr class="no-address" *ngIf="!selectedEmailAddressList">
                <td colspan="2">No address selected.</td>
            </tr>
        </tbody>
    </table>
</div>