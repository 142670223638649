<div class="d-flex flex-column mt-3 container mb-5" *ngIf="(project$ | async) && (templateGoalMap$ | async).size">
  <div class="d-flex justify-content-between">
    <h1 class="bold mt-3 mb-3 align-self-center">{{(project$ | async)?.name}}</h1>
      <div *ngIf="(projectGoalIds$ | async)?.length" class="form-group mt-3 mb-3">
        <label for="goal-select" class="bold">What do you want to measure?</label>
        <select #selectEditGoal class="form-control" id="goal-select" [ngModel]="(projectGoalId$ | async)" (change)="changeGoal(selectEditGoal.value)">
          <option *ngFor="let goal of (templateGoals$ | async)" [value]="goal.templateGoalId">{{goal.title}}</option>
        </select>
      </div>
  </div>
  <div class="mt-3 d-flex justify-content-center" *ngIf="!(projectGoalIds$ | async)?.length">
    <div class="form-group">
      <label for="goal-select" class="h2">What do you want to measure?</label>
      <select #selectNewGoal class="form-control" id="goal-select" [ngModel]="(projectGoalId$ | async)" (change)="changeGoal(selectNewGoal.value)">
        <option *ngFor="let goal of (templateGoals$ | async)" [value]="goal.templateGoalId">{{goal.title}}</option>
      </select>
    </div>
  </div>
  <div *ngIf="(projectGoalIds$ | async)?.length">
    <div class="form-group instruction-wrapper">
      <label class="h3" for="instructions">
        Survey Instructions/Description
        <em>(optional)</em>
        <br>
        <span class="italic instruction-sub-text mt-2">Provide a short description or instructions for survey takers.</span>
      </label>

      <textarea #instructions class="form-control" id="instructions" rows="4" [ngModel]="(instructions$ | async)" (change)="onInstructionsUpdate(instructions.value)"></textarea>
    </div>
    <div *ngFor="let goalId of (projectGoalIds$ | async)" class="d-flex flex-column my-3">
      <edit-goal-accordion
        [goal]="(templateGoalMap$ | async)?.get(goalId)">
      </edit-goal-accordion>
    </div>
    <div class="d-flex justify-content-end mt-2">
      <button class="btn btn-link px-4 py-2" (click)="cancel()">Cancel</button>
      <button class="btn btn-primary box-shadow px-4 py-2" (click)="saveGoals()" [disabled]="(customInputError$ | async)">
        Save
      </button>
    </div>
  </div>
</div>
