import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMessage } from '../../shared/models/message';
import { MESSAGE_ACTIONS } from '../message/message.actions';


@Injectable()
export class MessageEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
  ) {}

  danger$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MESSAGE_ACTIONS.danger),
      map(({ errorResponse }) => {
        const message =
          errorResponse?.error?.message ??
          'An unexpected error has occurred. Please <a href="https://campuslabs.zendesk.com/hc/en-us/requests/new?ticket_form_id=211368" target ="_blank">contact support</a>.';

        const id = Date.now(); //the id is just now in milliseconds

        const payload: IMessage = {
          timeToLive: 10000,
          type: 'danger',
          icon: 'icon-remove-sign',
          message,
          id,
        };

        return MESSAGE_ACTIONS.showMessage({ payload });
      }),
    ),
  );

  success$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MESSAGE_ACTIONS.success),
      map(({ message }) => {
        const id = Date.now(); //the id is just now in milliseconds

        const payload: IMessage = {
          timeToLive: 10000,
          type: 'success',
          icon: 'icon-check',
          message,
          id,
        };

        return MESSAGE_ACTIONS.showMessage({ payload });
      }),
    ),
  );

  warning$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MESSAGE_ACTIONS.warning),
      map(({ message }) => {
        const id = Date.now(); //the id is just now in milliseconds

        const payload: IMessage = {
          timeToLive: 5000,
          type: 'warning',
          icon: 'icon-warning',
          message,
          id,
        };

        return MESSAGE_ACTIONS.showMessage({ payload });
      }),
    ),
  );

  info$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MESSAGE_ACTIONS.info),
      map(({ message }) => {
        const id = Date.now(); //the id is just now in milliseconds

        const payload: IMessage = {
          timeToLive: 5000,
          type: 'info',
          icon: 'icon-info',
          message,
          id,
        };

        return MESSAGE_ACTIONS.showMessage({ payload });
      }),
    ),
  );

  //listen for when any message is shown, close it after time to live
  hideMessageAfterTTL$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MESSAGE_ACTIONS.showMessage),
      map(({ payload }) => {

        //set a timeout to hide the message
        if (payload.timeToLive > 0) {
          setTimeout(() => {
            this.store.dispatch(MESSAGE_ACTIONS.hideMessage({messageId: payload.id}));
          }, payload.timeToLive);
        }

        return MESSAGE_ACTIONS.none();
      }),
    ),
  );

}
