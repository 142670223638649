import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { RecipientList } from '../../../shared/models/mass-mail-form';

@Component({
  selector: 'address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
})
export class AddressListComponent implements OnInit {
  @Input() selectedEmailAddressList: RecipientList[];
  @Input() isDisabled: boolean;
  @Output() onRemoveEmail = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: any) {
    if (changes['selectedEmailAddressList']) {
      if (this.selectedEmailAddressList) { }
    }
  }

  removeEmail(email: RecipientList): void {
    if (this.isDisabled) {
      return;
    }
    this.onRemoveEmail.emit(email);
  }

  removeAllEmails(): void {
    this.onRemoveEmail.emit(null);
  }
}
