<form [formGroup]="copymailForm" (ngSubmit)="submitMassmail()">
    <div class="form-group col-sm-12 mb-5">
        <label for="selectedProjectId" class="addresses-label">Project</label>
        <select class="form-control" id="selectedProject" (change)="selectProject()" formControlName="selectedProjectId"
            required>
            <ng-container *ngFor="let project of (projects$ | async) | sortByProjectName; let i = index;">
                <option *ngIf="project" [value]="project.projectId">
                    {{ project?.name}}</option>
            </ng-container>
        </select>
    </div>
    <div class="form-group col-sm-12 mb-5">
        <label for="selectedMailing">Mailing</label>
        <select class="form-control" id="selectedMailing" (change)="getEmailAddressFromMailing()"
            formControlName="selectedMailing" required>
            <option *ngFor="let emailResult of (massMailStatus$ | async)?.projectEmailResultList;"
                [value]="emailResult.projectEmailId">{{ emailResult?.description}}</option>
        </select>
    </div>
    <div class="cb">
        <input id="copyRecipients" type="checkbox" name="copyRecipients" formControlName="copyRecipients"
            checked="checked">
        <label for="copyRecipients">Copy Email Recipients</label>
    </div>
    <ng-container *ngIf="copymailForm.controls['selectedMailing'].value && selectedEmailResult">
        <div class="form-group col-sm-12">
            <label><b>Subject:</b> {{selectedEmailResult?.subject}} </label>
        </div>
        <div class="form-group col-sm-12">
            <label><b>Created By:</b> {{selectedEmailResult?.createdByUsername }} </label>
        </div>
        <div class="form-group col-sm-12">
            <label><b>Date Created:</b> {{selectedEmailResult?.dateCreated }} </label>
        </div>
        <div class="form-group col-sm-12">
            <label><b>Address Count:</b> {{selectedEmailResult?.recipientList.length}}</label>
        </div>
    </ng-container>

    <div class="form-group col-sm-12 mt-2">
        <div class="col-sm-6 d-inline-block pl-0 text-left">
        </div>
        <div class="col-sm-6 d-inline-block pl-0 pr-0 text-right">
            <button class="btn btn-link" type="button" (click)="close()">Cancel</button>
            <button class="btn approve-btn dark-blue-btn box-shadow d-inline-block"
                (click)="createMailing()">Copy</button>
        </div>
    </div>
</form>