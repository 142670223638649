import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ProjectType } from '../../../shared/enums/project-type';
import { IProject, IProjectWithGoalsResponse } from '../../../shared/models/project';
import { ProjectHttpService } from '../../../shared/services/communication/project-http.service';
import { MESSAGE_ACTIONS } from '../../../store/message/message.actions';
import { PROJECT_ACTIONS } from './project.actions';


@Injectable()
export class ProjectEffects {

  constructor(
    private actions$: Actions,
    private projectHttpService: ProjectHttpService,
    private router: Router,
  ) { }

  checkIsLinkValid$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(PROJECT_ACTIONS.checkIsLinkValid),
      mergeMap(({ link, projectId }) =>
        this.projectHttpService.isLinkAvailable(link, projectId).pipe(
          map((isValid: boolean) =>
            PROJECT_ACTIONS.storeIsLinkValid({isValid}),
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({errorResponse})),
          ),
        ),
      ),
    ),
  );

  getProject$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(PROJECT_ACTIONS.getProjectById),
      mergeMap(({ projectId }) =>
        this.projectHttpService.getProjectById(projectId).pipe(
          map((project: IProject) =>
            PROJECT_ACTIONS.storeProject({ project }),
          ),
          // Should we make a catch all 404 page?
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse })),
          ),
        ),
      ),
    ),
  );

  storeUpdatedProject$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(PROJECT_ACTIONS.storeEditProject),
      mergeMap(({ projectId, projectReq }) =>
        this.projectHttpService.updateProject(projectId, projectReq).pipe(
          map((project: IProject) =>{
            // When the project is updated redirect to baseline proper.
            const projectDashboardUrl = this.getProjectDashboardUrl(project);
            window.location.assign(projectDashboardUrl);
            return MESSAGE_ACTIONS.success({message: 'Project Saved Successfully'});
          }),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse })),
          ),
        ),
      ),
    ),
  );

  createProject$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(PROJECT_ACTIONS.createProject),
      mergeMap(({ payload }) =>
        this.projectHttpService.createProject(payload).pipe(
          map((newProject: IProject) => {
            //conditionally route to edit goals for GOP or to project dashboard for free-form
            if (newProject.type === ProjectType.Goal) {
              this.router.navigate(['project', newProject.projectId, 'edit', ProjectType[newProject.type].toLocaleLowerCase()]);
            } else {
              const projectDashboardUrl = this.getProjectDashboardUrl(newProject);
              window.location.assign(projectDashboardUrl);
            }

            return MESSAGE_ACTIONS.success({message: 'Project Created Successfully'});
          }),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse, loaderKey: PROJECT_ACTIONS.createProject.type })),
          ),
        ),
      ),
    ),
  );

  deleteProject$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(PROJECT_ACTIONS.deleteBulkProjects),
      mergeMap(({ organizationId, projectIds}) =>
        this.projectHttpService.deleteProjects(organizationId, projectIds).pipe(
          map(() => {
            return MESSAGE_ACTIONS.success({message: 'Project deletion queued. You will receive an email once complete. You may leave this page.'});
          }),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse, loaderKey: PROJECT_ACTIONS.deleteBulkProjects.type })),
          ),
        ),
      ),
    ),
  );

  storeErrorSavingProject$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(PROJECT_ACTIONS.errorSavingProject),
      map(({ errorResponse }) =>
        MESSAGE_ACTIONS.danger({ errorResponse: errorResponse, loaderKey: PROJECT_ACTIONS.postProjectGoals.type }),
      ),
    ),
  );

  postProjectGoals$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(PROJECT_ACTIONS.postProjectGoals),
      mergeMap(({ projectId, projectGoalRequests, instructions }) =>
        this.projectHttpService.patchProjectGoals(projectId, {instructions: instructions, goals: projectGoalRequests}).pipe(
          map((newProject: IProject) =>{
            const projectDashboardUrl = this.getProjectDashboardUrl(newProject);
            window.location.assign(projectDashboardUrl);

            return MESSAGE_ACTIONS.success({message: 'Project Goal Saved Successfully'});
          }),
          catchError((errorResponse: HttpErrorResponse) =>
            of(PROJECT_ACTIONS.errorSavingProject({ errorResponse })),
          ),
        ),
      ),
    ),
  );

  updateQuestionText$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(PROJECT_ACTIONS.updateQuestionText),
      map(({ templateLookUp, questionTextSeparation }) => {
        // Join the separation of the questionText back together.
        const newQuestionText = questionTextSeparation.reduce((str, curr) => {
          if (curr.isInput) {
            // Remove any extra space the user entered.
            const cleanedText = curr.text.trim();
            // Strip any {}'s in the input. Then wrap the input in {}'s
            return `${str}{${cleanedText.replace(/[{}]/g, '')}}`;
          } else {
            return `${str}${curr.text}`;
          }
        }, '');

        return PROJECT_ACTIONS.storeQuestionText({ templateLookUp, newQuestionText });
      }),
    ),
  );

  validateCustomQuestionText$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(PROJECT_ACTIONS.updateQuestionText),
      map(({ questionTextSeparation }) => {
        let hasError = false;
        questionTextSeparation.forEach(qts => {
          if (qts.isInput && qts.text.length === 0) {
            hasError = true;
          }
        });

        return PROJECT_ACTIONS.storeHasCustomQuestionTextError({ hasError });
      }),
    ),
  );

  storeProjectAndGoals$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(PROJECT_ACTIONS.getProjectWithGoals),
      mergeMap(({ payload }) =>
        this.projectHttpService.getProjectWithGoals(payload).pipe(
          map((projectWithGoals: IProjectWithGoalsResponse) =>
            PROJECT_ACTIONS.storeProjectWithGoals({ payload: projectWithGoals }),
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse, loaderKey: PROJECT_ACTIONS.getProjectWithGoals.type })),
          ),
        ),
      ),
    ),
  );

  checkProjectResponses$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(PROJECT_ACTIONS.storeProjectWithGoals),
      map(({ payload }) =>
        payload.hasResponses
          ? MESSAGE_ACTIONS.warning({message: 'This Project already has Responses'})
          : MESSAGE_ACTIONS.none(),
      ),
    ),
  );

  getProjectDashboardUrl(newProject: IProject): string {
    let origin = window.location.origin;
    if (origin === 'https://localhost:4200') {
      origin = 'https://localhost';
    }

    return `${origin}/app/ClientWeb/project.aspx?projectid=${newProject.projectId}`;
  }

}
