import { Component, OnInit } from '@angular/core';
import { UrlConstantsService } from '../../shared/services/utils/url-constants.service';
import { UserSettingsService } from '../../shared/services/user-settings.service';
import { ProjectType } from '../../shared/enums/project-type';
import { Store } from '@ngrx/store';
import { PROJECT_ACTIONS } from '../store/project/project.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-project-type',
  templateUrl: './select-project-type.component.html',
  styleUrls: ['./select-project-type.component.scss'],
})
export class SelectProjectTypeComponent implements OnInit {
  requestProjectUrl: string;
  createProjectUrl: string;
  projectTypeEnum = ProjectType;

  constructor(
    private urlConstantsService: UrlConstantsService,
    public userSettingsService: UserSettingsService,
    private store: Store,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.requestProjectUrl = this.urlConstantsService.getRequestProjectUrl();
    this.createProjectUrl = this.urlConstantsService.getCreateProjectUrl();
  }

  selectProjectType(selectionUrl: string, projectType?: ProjectType) {
    // put project type in state so that we know what to display in create-project
    this.store.dispatch(PROJECT_ACTIONS.storeProjectType({ projectType }));

    // navigate to create-project.component or to baseline proper if it's a project request
    if (projectType === ProjectType.Form || projectType === ProjectType.Goal) {
      this.router.navigate([selectionUrl]);
    } else {
      window.location.assign(selectionUrl);
    }
  }

}
