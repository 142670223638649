import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TopbarComponent } from '@campuslabs/library-top-bar-ng2';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ICurrentUserAccount } from './shared/models/account';
import { UserSettingsService } from './shared/services/user-settings.service';
import { ACCOUNT_ACTIONS } from './store/account/account.actions';
import { ACCOUNT_SELECTORS } from './store/account/account.reducer';
import { APP_INSIGHTS_ACTIONS } from './store/application-insights/app-insights.action';

@Component({
  selector: 'survey-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(TopbarComponent) topBar: TopbarComponent;
  currentAccount$: Observable<ICurrentUserAccount>;

  title = 'surveys';
  year = new Date().getFullYear();
  topbarConfig = {
    productInfo: { name: 'Baseline' },
    urls: {
      productHomeLink: window.location.origin + environment.HOME_PAGE_PATH,
      homepage: window.location.origin + environment.HOME_PAGE_PATH,
      profile: window.location.origin + environment.PROFILE_PATH,
    },
    signOutFn: (): void => { this.userSettingsService.signOut(); },
    helpFn: (): void => { window.open(environment.HELP_URL); },
    userInfo: {
      account: {
        externalId: '1234',
        id: 1234,
        firstName: 'First',
        lastName: 'Last',
      },
      institution: {
        institutionId: 1,
        name: 'Campus Labs University',
      },
    },
  };

  constructor(
    public userSettingsService: UserSettingsService,
    private store: Store,
    public _router: Router,
  ) {
    this.store.dispatch(APP_INSIGHTS_ACTIONS.startAppInsights());
  }

  ngOnInit() {
    this.store.dispatch(ACCOUNT_ACTIONS.getCurrentAccount());

    this.currentAccount$ = this.store.select(ACCOUNT_SELECTORS.getCurrentUserAccount);

    this.currentAccount$.subscribe((account: ICurrentUserAccount) => {
      this.topbarConfig.userInfo.account.externalId = account.account.externalId;
      this.topbarConfig.userInfo.account.id = account.account.userId;
      this.topbarConfig.userInfo.account.firstName = account.account.firstName;
      this.topbarConfig.userInfo.account.lastName = account.account.lastName;
      this.topbarConfig.userInfo.institution.institutionId = account.institution.id;
      this.topbarConfig.userInfo.institution.name = account.institution.name;
    });

    //track pages via app cues
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (window['Appcues']) {
          window['Appcues'].page();
        }
      });
  }
}
