import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/surveys/src/environments/environment';
import { Observable } from 'rxjs';
import { IOrgUnit } from '../../models/org-unit';
import { IOrganization } from '../../models/organization';
import { BaseHttpService } from './base-http.service';

@Injectable({providedIn: 'root'})
export class OrganizationHttpService extends BaseHttpService {
  constructor(h: HttpClient) {
    super(h);
  }

  private _buildUrl(append = ''): string {
    return `${environment.BASELINE_API}/organizations/${append}`;
  }

  getOrganizations(): Observable<IOrganization[]> {
    return this.getRequest({
      url: this._buildUrl('organizations'),
      query: null,
    });
  }

  getOrgUnits(): Observable<IOrgUnit[]> {
    return this.getRequest({
      url: this._buildUrl('orgunits'),
      query: null,
    });
  }
}
