import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessagesComponent } from './components/messages.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ContainerCardComponent } from './components/container-card/container-card.component';

const _modules = [
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
];

const _components = [
  MessagesComponent,
  LoaderComponent,
  ContainerCardComponent,
];

@NgModule({
  imports: _modules,
  declarations: [..._components],
  exports: [..._modules, ..._components],
})
export class SharedModule {}
