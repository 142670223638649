<div class="messages {{ (message$ | async)?.type }}" [class.slideIn]="(showMessage$ | async)" aria-live="assertive" role="alert">
  <div *ngIf="(message$ | async) as message">

    <button class="close" (click)="onClose( message.id )">
      <span aria-hidden="true">&times;</span>
      <span class="sr-only">Close</span>
    </button>

    <div class="message-icon">
      <i class="icon {{ message.icon }}"></i>
    </div>

    <div class="message" [innerHTML]="message.message"></div>

  </div>
</div>