import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { ITemplateDimension, ITemplateGoal, ITemplateQuestionSeparation } from 'projects/surveys/src/app/shared/models/template-goal';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PROJECT_ACTIONS } from '../../../store/project/project.actions';
import { PROJECT_SELECTORS } from '../../../store/project/project.reducer';

@Component({
  selector: 'edit-goal-accordion',
  templateUrl: './edit-goal-accordion.component.html',
  styleUrls: ['./edit-goal-accordion.component.scss'],
})
export class EditGoalAccordionComponent implements OnInit {
  @Input() goal: ITemplateGoal;
  projectDimensions$: Observable<Map<number, ITemplateDimension>>;
  projectQuestionSeparation$: Observable<Map<number, ITemplateQuestionSeparation[]>>;
  @ViewChild(NgbAccordion) accordion: NgbAccordion;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.projectDimensions$ = this.store.pipe(
      select(PROJECT_SELECTORS.getProjectGoals),
    ).pipe(map(map => map.get(this.goal.templateGoalId)));

    this.projectQuestionSeparation$ = this.store.select(PROJECT_SELECTORS.getSeparatedQuestionText);
  }

  ngAfterViewInit() {
    // Open all panels that are apart of this project
    this.projectDimensions$.pipe(take(1)).subscribe(dimensions => {
      dimensions.forEach(dim => this.accordion.expand(dim.dimensionPanelId));
    });
  }

  updateText(templateDimensionId: number, templateQuestionId: number, questionTextSeparation: ITemplateQuestionSeparation[]) {
    this.store.dispatch(PROJECT_ACTIONS.updateQuestionText({
      templateLookUp: {
        templateGoalId: this.goal.templateGoalId,
        templateDimensionId,
        templateQuestionId,
      },
      questionTextSeparation,
    }));
  }

  toggleAll() {
    // Update UI before sending the action to update the store.
    this.projectDimensions$.pipe(
      take(1),
    ).subscribe(dimensions => {
      if (dimensions.size === this.goal.templateDimensions.length) {
        this.accordion.collapseAll();
        this.store.dispatch(PROJECT_ACTIONS.storeToggleGoalRemove({ templateGoalId: this.goal.templateGoalId }));
      } else {
        this.accordion.expandAll();
        this.store.dispatch(PROJECT_ACTIONS.storeToggleGoalAdd({ templateDimensions: this.goal.templateDimensions, templateGoalId: this.goal.templateGoalId }));
      }
    });

  }

  clickDimension(dimension: ITemplateDimension) {
    this.store.dispatch(PROJECT_ACTIONS.storeToggleDimension({ templateDimension: dimension, templateGoalId: this.goal.templateGoalId }));
    this.accordion.toggle(dimension.dimensionPanelId);
  }
}
