import { Injectable } from '@angular/core';
import { environment } from 'projects/surveys/src/environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { APP_INSIGHTS_ACTIONS } from './app-insights.action';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Injectable()
export class AppInsightsEffects {
  appInsights: ApplicationInsights;

  constructor(
    public router: Router,
    private actions$: Actions,
  ) { }

  startAppInsights$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(APP_INSIGHTS_ACTIONS.startAppInsights),
      map(() => {
        if (!this.appInsights)
        {
          const angularPlugin = new AngularPlugin();
          this.appInsights = new ApplicationInsights({
            config: {
              instrumentationKey: environment.APP_INSIGHTS_INSTRUMENTATION_KEY,
              extensions: [angularPlugin],
              extensionConfig: {[angularPlugin.identifier]: { router: this.router }},
            },
          });
          this.appInsights.loadAppInsights();
        }

        return APP_INSIGHTS_ACTIONS.finishAppInsightsAction();

      }),
    ),
  )

  trackEventAI$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(APP_INSIGHTS_ACTIONS.trackEvent),
      map(({ name, properties }) => {
        this.appInsights.trackEvent({ name, properties });

        return APP_INSIGHTS_ACTIONS.finishAppInsightsAction();
      }),
    ),
  )
}
