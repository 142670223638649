import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { MassmailHttpService } from '../../../shared/services/communication/massmail-http.service';
import { MESSAGE_ACTIONS } from '../../../store/message/message.actions';
import { MASSMAIL_ACTIONS } from './massmail.actions';
import { IMassMailStatus } from '../../../shared/models/mass-mail-status';
import { saveAs } from 'file-saver';

@Injectable()
export class MassmailEffects {

  constructor(
    private actions$: Actions,
    private massmailHttpService: MassmailHttpService,
    //private store: Store,
  ) { }

  getMassMail$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAIL_ACTIONS.getMassMailByProjectId),
      mergeMap(({ projectId }) =>
        this.massmailHttpService.getMassMail(projectId).pipe(
          map((massMail: IMassMailStatus) => {
            return MASSMAIL_ACTIONS.storeMassMail({ massMail });
          },
          ),
          // Should we make a catch all 404 page?
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse })),
          ),
        ),
      ),
    ),
  );

  // AJB: As discussed, this is the future state of the loader: explicitly called within the effect
  //      when you want it to show and when you want it to hide
  //
  // getMassMail$ = createEffect((): Observable<Action> =>
  //   this.actions$.pipe(
  //     ofType(MASSMAIL_ACTIONS.getMassMailByProjectId),
  //     mergeMap(({ projectId }) => {
  //       this.store.dispatch(LOADER_ACTIONS.showLoadIndicator({ keyName: MASSMAIL_ACTIONS.getMassMailByProjectId.type }));
  //       return this.massmailHttpService.getMassMail(projectId).pipe(
  //         map((massMail: IMassMailStatus) => {
  //           this.store.dispatch(LOADER_ACTIONS.hideLoadIndicator({ keyName: MASSMAIL_ACTIONS.getMassMailByProjectId.type }));
  //           return MASSMAIL_ACTIONS.storeMassMail({ massMail });
  //         },
  //         ),
  //         // Should we make a catch all 404 page?
  //         catchError((errorResponse: HttpErrorResponse) =>
  //           of(MESSAGE_ACTIONS.danger({ errorResponse })),
  //         ),
  //       );
  //     }),
  //   ),
  // );

  getMassMailByEmailId$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAIL_ACTIONS.getMassMailByEmailId),
      mergeMap(({ projectId, emailId }) => {
        return this.massmailHttpService.getMassMailByEmailId(projectId, emailId).pipe(
          map((result: any) => {
            return MASSMAIL_ACTIONS.storeMassMailByEmailId({ editMassMail: result });
          },
          ),
          // Should we make a catch all 404 page?
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse }))),
        );
      },
      ),
    ),
  );

  getMassMailReminderByEmailId$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAIL_ACTIONS.getMassMailReminderByEmailId),
      mergeMap(({ projectId, emailId }) => {
        return this.massmailHttpService.getMassMailReminderByEmailId(projectId, emailId).pipe(
          map((result: any) => {
            return MASSMAIL_ACTIONS.storeMassMailByEmailId({ editMassMail: result });
          },
          ),
          // Should we make a catch all 404 page?
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse }))),
        );
      },
      ),
    ),
  );

  getProjectByProjectId$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAIL_ACTIONS.getProjectByProjectId),
      mergeMap(({ projectId }) =>
        this.massmailHttpService.getProjectByProjectId(projectId).pipe(
          map((result: any) => {
            return MASSMAIL_ACTIONS.storeProject({ project: result });
          },
          ),
          // Should we make a catch all 404 page?
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse })),
          ),
        ),
      ),
    ),
  );

  getPanelsByProjectId$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAIL_ACTIONS.getPanelsByProjectId),
      mergeMap(({ projectId }) =>
        this.massmailHttpService.getPanelsByProjectId(projectId).pipe(
          map((result: any) => {
            return MASSMAIL_ACTIONS.storePanels({ panels: result });
          },
          ),
          // Should we make a catch all 404 page?
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse })),
          ),
        ),
      ),
    ),
  );

  getRecipientListByPanelId$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAIL_ACTIONS.getRecipientListByPanelId),
      mergeMap(({ panelId, projectId }) =>
        this.massmailHttpService.getRecipientListByPanelId(panelId, projectId).pipe(
          map((result: any) => {
            return MASSMAIL_ACTIONS.storeRecipientList({ recipientList: result });
          },
          ),
          // Should we make a catch all 404 page?
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse })),
          ),
        ),
      ),
    ),
  );

  getAllProjects$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAIL_ACTIONS.getAllProjects),
      mergeMap(() =>
        this.massmailHttpService.getAllProjects().pipe(
          map((result: any) => {
            return MASSMAIL_ACTIONS.storeAllProjects({ projects: result });
          },
          ),
          // Should we make a catch all 404 page?
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse })),
          ),
        ),
      ),
    ),
  );

  getPreviewMailingFormat$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAIL_ACTIONS.getPreviewMailingFormat),
      mergeMap(({ projectId, emailId, massmailform }) =>
        this.massmailHttpService.getPreviewMailingFormat(projectId, emailId, massmailform).pipe(
          map((result: any) => {
            return MASSMAIL_ACTIONS.storePreviewMailingFormat({ emailResult: result });
          }),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse, loaderKey: MASSMAIL_ACTIONS.getPreviewMailingFormat.type })),
          ),
        ),
      ),
    ),
  );

  deleteMassMail$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAIL_ACTIONS.deleteMassmailByProjectId),
      mergeMap(({ projectId, emailId }) =>
        this.massmailHttpService.deleteMassMail(projectId, emailId).pipe(
          map(() =>
            MASSMAIL_ACTIONS.getMassMailByProjectId({ projectId }),
          ),
          // Should we make a catch all 404 page?
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse })),
          ),
        ),
      ),
    ),  
  );

  getUndeliveredFile$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAIL_ACTIONS.downloadUndeliveredFile),
      mergeMap(({ projectId: projectId, emailId: emailId }) =>
        this.massmailHttpService.downloadUndeliveredFile(projectId, emailId).pipe(
          map((result: ArrayBuffer) => {
            return MASSMAIL_ACTIONS.storeUndeliveredFile({ undeliveredFile: result });
          }),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse })),
          ),
        ),
      ),
    ),
  );
}

