import { ActionReducerMap } from '@ngrx/store';
import { AccountEffects } from './account/account.effects';
import { accountReducer, IAccountState } from './account/account.reducer';
import { AppInsightsEffects } from './application-insights/app-insights.effect';
import { LoaderEffects } from './loader/loader.effect';
import { ILoaderState, loaderReducer } from './loader/loader.reducer';
import { MessageEffects } from './message/message.effects';
import { messageReducer, IMessageState } from './message/message.reducer';

export interface IAppState {
  account: IAccountState;
  message: IMessageState;
  loader: ILoaderState;
}

export const EFFECTS = [
  AccountEffects,
  MessageEffects,
  LoaderEffects,
  AppInsightsEffects,
];

export const REDUCERS: ActionReducerMap<IAppState> = {
  account: accountReducer,
  message: messageReducer,
  loader: loaderReducer,
};
