<div class="ml-4">
  <div class="d-flex align-items-center flex-wrap ml-2" *ngIf="localQuestionSeparation?.length > 0">
    <nx-natural-language-form [formGroup]="projectForm" size="small" nxNegative="true">
      <ng-container *ngFor="let projectTextSection of localQuestionSeparation; let i = index">
          <nx-word *ngIf="projectTextSection.isInput" nxLabel='Provide this required field' [ngClass]="{'invalid-nxInput': projectTextSection.text.length === 0}">
            <input nxInput aria-live="polite" type="text" [(ngModel)]="projectTextSection.text" [formControlName]="'customInput' + question.templateQuestionId + i" required>
            <div nxError class="inline">
              <span class="message-icon pr-2"><i class="bi-exclamation-triangle-fill danger"></i></span>This field is required.
            </div>
          </nx-word>
        <span *ngIf="!projectTextSection.isInput" class="bold mb-0">{{projectTextSection.text}}</span>
      </ng-container>
    </nx-natural-language-form>
  </div>
  <div class="ml-4">
    <span class="d-flex align-items-center p-1" *ngFor="let answer of question?.templateAnswers">
      <input type="radio" disabled="true" [id]="'answer-' + answer.templateAnswerId">
      <label class="mb-0 ml-2" [for]="'answer-' + answer.templateAnswerId">{{answer.answerText}}</label>
    </span>
  </div>
</div>
