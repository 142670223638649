<div class="d-flex flex-column mt-3">
  <div class="container card">
    <div class="card-body mailing">
      <div class="">
        <!-- <h2 class="card-title" *ngIf="!isCopy && emailAction !='reminder'">{{formConfig.emailId &&
          formConfig.emailId != -1 ? 'Edit' : 'New'}} {{isReminder ? 'Reminder' : 'Mailing'}}</h2>
        <h2 class="card-title" *ngIf="isCopy">Copy Mailing</h2>
        <h2 class="card-title" *ngIf="emailAction =='reminder'">Create Reminder</h2> -->
        <h2 class="card-title">{{getTitle()}}</h2>
      </div>
      <div class="d-flex">
        <div class="section-left">
          <ng-container *ngIf="emailAction !='copy'">
            <general-info [generalInfo]="{
              state: formConfig?.state,
              action: emailAction,
              project: formConfig?.project,
              scheduledSendDate: formConfig?.emailResult?.scheduledSendDate,
              actualSendDate: formConfig?.emailResult?.actualSendDate,
              mailCreatedBy: formConfig?.emailCreatedBy}"></general-info>
            <massmail-form [config]="formConfig" [formData]="formConfig.emailResult"></massmail-form>
          </ng-container>
          <ng-container *ngIf="emailAction =='copy'">
            <copymail-form [config]="formConfig"></copymail-form>
          </ng-container>
        </div>
        <div class="section-right" *ngIf="isCopy || emailAction =='copy'">
          <h4>Instructions</h4>
          <ol class="instructions-list" *ngIf="emailAction == 'create'">
            <li id="Content_instructions1"><strong>Insert the recipients'email addresses:</strong>
              Click on the <strong>"Add Addresses"</strong> button to enter the email addresses of the
              individuals who will receive this mailing.</li>
            <li id="Content_instructions2"><strong>Complete the required fields:</strong> From Name,
              Reply-to Address, Subject, and Message. Be sure to include the survey instructions in your
              message.</li>
            <li id="Content_instructions3"><strong>Decide when to send the email:</strong> Send the email
              immediately or schedule a date and time in the future. Note, the scheduled
              date and time must be within the project’s active date range.</li>
            <li id="Content_instructions4"><strong>Add a description (optional): </strong> Add additional
              details about the mailing to reference later. This information is not visible to recipients.
            </li>
            <li id="Content_instructions4"><strong>Preview your mailing:</strong> Click
              <strong>“Preview”</strong> to see how the message will appear to recipients.
            </li>
            <li id="Content_instructions4"><strong>Schedule the mailing</strong>: Click
              <strong>“Send Now”</strong> to send your mailing immediately or <strong>"Schedule"</strong>
              to send your message at the designated date and time. Only one option will appear based on
              what you selected in step 3.
            </li>
          </ol>
          <ng-container *ngIf="emailAction == 'copy'">
            <ol class="instructions-list">
              <li id="Content_instructions1">Select a Project from the dropdown menu.</li>
              <li id="Content_instructions2">Select a Mailing from the dropdown menu. The options will be limited to
                those from
                the selected Project.</li>
              <li id="Content_instructions3">Click "Copy".</li>
            </ol>
            <p>
              <i>
                Note: You will only be able to select from Projects that you have access to view in Baseline.
              </i>
            </p>
          </ng-container>
        </div>
        <div class="section-right" *ngIf="!isCopy && formConfig.state.toLowerCase() != 'sent' && emailAction != 'copy'">
          <h4>Instructions</h4>
          <ol class="instructions-list">
            <li id="Content_instructions1"><strong>Insert the recipients' email addresses:</strong>
              Click on the <strong>"Add Addresses"</strong> button to enter the email addresses of the
              individuals who will receive this mailing.</li>
            <li id="Content_instructions2"><strong>Complete the required fields:</strong> From Name,
              Reply-to Address, Subject, and Message. Be sure to include the survey instructions in your
              message.</li>
            <li id="Content_instructions3"><strong>Decide when to send the email:</strong> Send the email
              immediately or schedule a date and time in the future. Note, the scheduled
              date and time must be within the project’s active date range.</li>
            <li id="Content_instructions4"><strong>Add a description (optional): </strong> Add additional
              details about the mailing to reference later. This information is not visible to recipients.
            </li>
            <li id="Content_instructions4"><strong>Write your email message.</strong> Be sure to click
              <strong>“Insert Instructions”</strong> to include the link to the survey in your message.
            </li>
            <li id="Content_instructions4"><strong>Preview your mailing:</strong> Click
              <strong>“Preview”</strong> to see how the message will appear to recipients.
            </li>
            <li id="Content_instructions4"><strong>Schedule the mailing</strong>: Click
              <strong>“Send Now”</strong> to send your mailing immediately or <strong>"Schedule"</strong>
              to send your message at the designated date and time. Only one option will appear based on
              what you selected in step 3.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>