import { Component, OnInit, Input, SimpleChanges, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MASSMAILFORM_ACTIONS } from '../../store/massmailform/massmailform.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, takeLast } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { QuillConfigModule } from 'ngx-quill/config';
import { Observable } from 'rxjs';
import { MASSMAIL_SELECTORS } from '../../store/massmail/massmail.reducer';
import { IProjectEmailResult } from '../../../shared/models/project-email-result';
import { MASSMAIL_ACTIONS } from '../../store/massmail/massmail.actions';
import Quill from 'quill';

@Component({
  selector: 'massmail-form',
  templateUrl: './massmail-form.component.html',
  styleUrls: ['./massmail-form.component.scss'],
})
export class MassmailFormComponent implements OnInit {
  @Input() config: any;
  @Input() formData: IProjectEmailResult;
  @ViewChild('confirmScheduleModal', { static: true }) confirmScheduleModal: ElementRef;
  @ViewChild('editor') editor: any;
  projectId: number;
  emailId: number;
  emailAction: string;
  timezone: string;
  isDateTimeDisabled = false;
  isFormFieldDisabled = false;
  isAddEmailAddressDisabled = false;
  isRemoveEmailAaddressDisabled = false;
  isPreviewDisabled = false;
  instructionTitle: string;
  msgWithInstructions: string;
  hasInstructions: boolean;
  isShowHtml = false;
  massmailForm = new FormGroup(
    {
      from: new FormControl('', Validators.required),
      replyTo: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      subject: new FormControl('', Validators.required),
      htmlBody: new FormControl(''),
      description: new FormControl(''),
      scheduledSendDate: new FormControl(''),
      scheduledSendTime: new FormControl('', Validators.required),
      scheduledOrSendImmediately: new FormControl('schedule'),
      recipientList: new FormControl(null, Validators.required),
    },
    {
      validators: [
        //this.dateTimeValidator
      ],
    },
  );
  editorConfig: AngularEditorConfig;
  options = {};
  quillEditor: Quill
  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.route.paramMap.pipe(take(1)).subscribe(paramMap => {
      this.projectId = +paramMap.get('projectId');
      this.emailId = +paramMap.get('emailId');
    });
    this.emailAction = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;

    // This will change all Date types - when JSONified, they should get the correct format.
    Date.prototype.toJSON = function () { return moment(this).format(); };
    const icons = Quill.import('ui/icons');
    icons['undo'] = '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>' +
      '<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>';
    icons['redo'] = '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>' +
      '<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>';

    var fontSizeArr = ['9pt', '8px', '10px', '11px', '12px', '14px', '16px', '18px', '24px', '32px'];
    var Size = Quill.import('attributors/style/size');
    Size.whitelist = fontSizeArr;
    Quill.register(Size, true);

    this.options = {
      toolbar: {
        container: [[{ redo: 'redo' }, { undo: 'undo' }, 'bold', 'italic', 'underline', 'strike'
          , { 'script': 'sub' }, { 'script': 'super' }, { align: '' }, { align: 'center' }, { align: 'right' },
        { align: 'justify' }, { 'indent': '-1' }, { 'indent': '+1' },
        { list: 'bullet' }, { list: 'ordered' },
        { header: [1, 2, 3, 4, 5, false] },
        { size: fontSizeArr },
        { font: [] },
        { color: [] },
        { background: [] },
          'code-block', 'link', 'blockquote', 'clean',
          'hr',
        ]],
        handlers: {
          redo() { this.quill.history.redo(); }, undo() { this.quill.history.undo(); },
          'hr': function (value) {
            var range = this.quill.getSelection();
            if (range) {
              this.quill.insertEmbed(range.index, "hr", "null")
            }
            this.quill.format('hr', false);
          }
        }
      },

      history: { delay: 2000, maxStack: 500, userOnly: true }
    };
  }

  ngOnInit(): void {
    if (this.config) {
      //Todo: Revisit below condition
      if (this.config.state.toLowerCase() == 'scheduled' || this.config.state.toLowerCase() == 'sent') {
        this.isFormFieldDisabled = this.isDateTimeDisabled = true;
        this.isAddEmailAddressDisabled = true;
        this.isRemoveEmailAaddressDisabled = true;
      }
      if (this.emailAction == 'create' || this.emailAction == 'reminder') {
        this.isPreviewDisabled = true;
      }
      this.createEditorConfig();
      this.instructionTitle = '{INSTRUCTIONS}';
      this.hasInstructions = false;
    }
    //Defaulting to Eastern until we decide to switch to UTC
    //this.timezone = moment.tz(moment.tz.guess()).zoneAbbr();
    this.timezone = moment.tz('America/New_York').zoneAbbr();
  }

  created(quill: Quill) {
    this.quillEditor = quill;
  }


  get form() { return this.massmailForm; }
  get from() { return this.massmailForm.get('from'); }
  get replyTo() { return this.massmailForm.get('replyTo'); }
  get subject() { return this.massmailForm.get('subject'); }
  get scheduleDateTime() { return this.massmailForm.get('scheduledSendTime'); }
  get recipientList() { return this.massmailForm.get('recipientList'); }

  createEditorConfig() {
    this.editorConfig = {
      editable: !this.isFormFieldDisabled,
      spellcheck: true,
      sanitize: false,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      defaultFontName: 'Arial',
      toolbarHiddenButtons: [
        [],
        ['insertImage',
          'insertVideo'],
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText',
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
    };
  }

  createFormControls() {
    this.massmailForm = new FormGroup({
      from: new FormControl('', Validators.required),
      replyTo: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      subject: new FormControl('', Validators.required),
      htmlBody: new FormControl(''),
      description: new FormControl(''),
      scheduledSendDate: new FormControl('', Validators.required),
      scheduledSendTime: new FormControl('', Validators.required),
      scheduledOrSendImmediately: new FormControl('schedule'),
      recipientList: new FormControl(null, Validators.required),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formData']) {
      if (this.formData) {
        if (this.formData.state.toLowerCase() == 'scheduled' || this.formData.state.toLowerCase() == 'sent') {
          this.isFormFieldDisabled = this.isDateTimeDisabled = true;
          this.isAddEmailAddressDisabled = true;
          this.isRemoveEmailAaddressDisabled = true;
          if (this.editorConfig) {
            this.editorConfig.editable = false;
          }
        } else {
          this.isFormFieldDisabled = this.isDateTimeDisabled = false;
          if (this.editorConfig) {
            this.editorConfig.editable = true;
          }
          this.isRemoveEmailAaddressDisabled = false;
          if (this.formData.parentProjectEmailId) {
            this.isAddEmailAddressDisabled = true;
          } else {
            this.isAddEmailAddressDisabled = false;
          }
        }
        this.createFormControls();
        this.massmailForm.controls['from'].setValue(this.formData.from);
        this.massmailForm.controls['replyTo'].setValue(this.formData.replyTo);
        this.massmailForm.controls['subject'].setValue(this.formData.subject);
        this.massmailForm.controls['htmlBody'].setValue(this.formData.htmlBody);
        this.massmailForm.controls['description'].setValue(this.formData.description);
        this.massmailForm.controls['recipientList'].setValue(this.formData.recipientList);
        if (this.formData.scheduledSendDate) {
          //Setting scheduleDate timezone to EST
          const scheduleDate = moment.tz(this.formData.scheduledSendDate, 'America/New_York');
          const isCurrentBeforeScheduled = moment().isBefore(scheduleDate);
          if (isCurrentBeforeScheduled) {
            this.isDateTimeDisabled = false;
            this.massmailForm.controls['scheduledOrSendImmediately'].setValue('schedule');
            this.setScheduledSendDate(scheduleDate.format('MM/DD/yyyy'));
            this.setScheduledSendTime(scheduleDate.format('HH:mm'));
          }
          else {
            this.isDateTimeDisabled = true;
            this.massmailForm.controls['scheduledOrSendImmediately'].setValue('sendImmediate');
            // Only change the dates when the email is a draft
            if (this.formData.state.toLowerCase() != 'scheduled' && this.formData.state.toLowerCase() != 'sent') {
              this.setScheduledSendDate(moment().format('MM/DD/yyyy'));
              this.setScheduledSendTime(moment().format('HH:mm'));
            }
          }
        }
      }
    }
    if (changes['config']) {
      if (this.config) {
        this.massmailForm.controls['recipientList'].setValue(this.config.recipientList);
      }
    }
  }
  submitMassmail(): void { }

  handleChange(e): void {
    if (e?.target?.value) {
      let now = moment();

      if (e.target.value == 'schedule') {
        now = now.add(10, 'minutes');
        this.isDateTimeDisabled = false;
      } else if (e.target.value == 'sendImmediate') {
        this.isDateTimeDisabled = true;
      }
      this.setScheduledSendDate(now.format('MM/DD/yyyy'));
      this.setScheduledSendTime(now.format('HH:mm'));
    }
  }

  saveClose(): void {
    const createObj: IProjectEmailResult = this.getRequestModel();

    if (this.emailAction == 'reminder' || this.emailAction == 'create') {
      createObj.projectEmailId = null;
      this.store.dispatch(MASSMAILFORM_ACTIONS.createProjectEmail({ projectId: +this.projectId, massmailform: createObj }));
    }
    else {
      this.store.dispatch(MASSMAILFORM_ACTIONS.updateProjectEmail({ projectId: +this.projectId, emailId: this.emailId, massmailform: createObj }));
    }
  }

  getRequestModel(state: string = null): IProjectEmailResult {
    let sendDate: string = null;

    if (this.massmailForm.contains('scheduledSendDate') && this.massmailForm.contains('scheduledSendTime')) {
      if (this.massmailForm.get('scheduledSendDate').value) {
        sendDate = moment(this.massmailForm.get('scheduledSendDate').value).format('YYYY-MM-DDTHH:mm:ss');
      }
      else {
        sendDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
      }
      if (sendDate.length < 1) {
        sendDate = null;
      }
    }
    return {
      projectEmailId: this.formData?.projectEmailId || null,
      parentProjectEmailId: +this.formData?.parentProjectEmailId || null,
      scheduledSendDate: sendDate,
      from: this.massmailForm.get('from').value,
      replyTo: this.massmailForm.get('replyTo').value,
      cc: null,
      bcc: null,
      subject: this.massmailForm.get('subject').value,
      description: this.massmailForm.get('description').value,
      createdBy: 1,
      projectId: +this.projectId,
      reminder: (this.config?.parentProjectEmailId || null) != null,
      textBody: this.massmailForm.get('htmlBody').value.replace(/(<([^>]+)>)/ig, ''),
      htmlBody: this.replaceClassesWithInlineStyles(this.massmailForm.get('htmlBody').value),
      projectEmailStateId: 1,
      state: state || this.config.state,
      recipientList: this.massmailForm.controls['recipientList'].value || [],
    } as IProjectEmailResult;
  }

   replaceClassesWithInlineStyles(inputText) {
    // Replace class="ql-align-center" with style="text-align:center"
    inputText = inputText.replace(/class="ql-align-center"/g, 'style="text-align:center"');
    
    // Replace class="ql-align-left" with style="text-align:left"
    inputText = inputText.replace(/class="ql-align-left"/g, 'style="text-align:left"');
    
    // Replace class="ql-align-right" with style="text-align:right"
    inputText = inputText.replace(/class="ql-align-right"/g, 'style="text-align:right"');
    return inputText;
  }

  setScheduledSendDate(date: string): void {
    if (date && date.length > 0) {
      this.massmailForm.controls['scheduledSendDate'].setValue(date);
      const now = new Date();
      if (Date.parse(date) < new Date(this.config.ProjectOpenDate).setUTCHours(0, 0, 0, 0) ||
        Date.parse(date) > new Date(this.config.ProjectCloseDate).setUTCHours(0, 0, 0, 0)) {
        this.scheduleDateTime.setErrors({ projectDate: true });
      } else if (Date.parse(date) < now.setUTCHours(0, 0, 0, 0)) {
        this.scheduleDateTime.setErrors({ invalidDate: true });
      } else if (Date.parse(date) === now.setUTCHours(0, 0, 0, 0) && Date.parse(this.massmailForm.controls['scheduledSendTime'].value) < new Date().valueOf()) {
        this.scheduleDateTime.setErrors({ invalidDate: true });
      } else {
        this.scheduleDateTime.setErrors(null);
      }
    } else {
      this.massmailForm.controls['scheduledSendDate'].setValue(null);
      this.scheduleDateTime.setErrors({ required: true });
    }
  }

  setScheduledSendTime(time: string): void {
    if (time && time.length > 0) {
      let momentObject = moment();
      if (this.massmailForm.controls['scheduledSendDate'].value) {
        momentObject = moment(this.massmailForm.controls['scheduledSendDate'].value);
      }
      momentObject.set('hour', Number(time.split(':')[0]));
      momentObject.set('minute', Number(time.split(':')[1]));
      this.massmailForm.controls['scheduledSendTime'].setValue(momentObject.format());
      // Update send date is required
      this.massmailForm.controls['scheduledSendDate'].setValue(momentObject.format());
      const now = new Date();

      if (momentObject.valueOf() < new Date(this.config.ProjectOpenDate).valueOf() || momentObject.valueOf() > new Date(this.config.ProjectCloseDate).valueOf()) {
        this.scheduleDateTime.setErrors({ projectDate: true });
      } else if (momentObject.valueOf() < now.setSeconds(0, 0)) {
        this.scheduleDateTime.setErrors({ invalidDate: true });
      }
      else {
        this.scheduleDateTime.setErrors(null);
      }
    }
  }

  setRecipientsList(list) {
    if (list) {
      this.massmailForm.controls['recipientList'].setValue(list);
    } else {
      this.massmailForm.controls['recipientList'].setValue(null);
    }
  }

  close(): void {
    this.router.navigateByUrl(`/project/${this.projectId}/massmail`);
  }

  schedule(): void {
    const createObj = this.getRequestModel();
    if (this.emailAction == 'reminder' || this.emailAction == 'create') {
      createObj.projectEmailId = null;
      this.emailId = 0;
    }
    this.store.dispatch(MASSMAILFORM_ACTIONS.scheduleProjectEmail({ projectId: this.projectId, emailId: this.emailId, massmailform: createObj }));

    const result$: Observable<object> = this.store.select(MASSMAIL_SELECTORS.getMassMailByEmailId);
    result$.pipe(take(1)).subscribe({
      complete: () => {
      },
    });
  }

  unschedule(): void {
    const createObj = this.getRequestModel();

    this.store.dispatch(MASSMAILFORM_ACTIONS.unscheduleProjectEmail({ projectId: +this.projectId, emailId: this.emailId, massmailform: createObj }));

    const result$: Observable<object> = this.store.select(MASSMAIL_SELECTORS.getMassMailByEmailId);
    result$.pipe(take(1)).subscribe({
      complete: () => {
      },
    });
  }

  openModal(modal: TemplateRef<NgbModal>): void {
    this.modalService.open(modal, { centered: true, size: 'xl', backdrop: 'static', windowClass: 'add-email-address-modal' });
  }

  openPreviewModal(modal: TemplateRef<NgbModal>): void {
    const createObj = this.getRequestModel();
    this.store.dispatch(MASSMAIL_ACTIONS.getPreviewMailingFormat({ projectId: +this.projectId, emailId: this.emailId, massmailform: createObj }));
    const result$: Observable<object> = this.store.select(MASSMAIL_SELECTORS.getPreviewMailing);
    result$.subscribe((response: IProjectEmailResult) => {
      if (response && response.projectId == +this.projectId) {
        this.msgWithInstructions = response.htmlBody;
      }
    });
    this.modalService.open(modal, { centered: true, size: 'xl', backdrop: 'static', windowClass: 'preview-mailing-modal' });
  }

  openScheduleConfirmationModal(modal: TemplateRef<NgbModal>): void {
    this.modalService.open(modal, { centered: true, size: 'l', backdrop: 'static', windowClass: 'preview-mailing-modal' });
  }

  isInstructionAdded(): boolean {
    const msg: string = this.massmailForm.controls['htmlBody'].value;
    if (msg && msg.length > 0) {
      if (msg.match(this.instructionTitle)) {
        return true;
      }
    }
    return false;
  }

  onChangeShowHideHtml() {
    this.isShowHtml = !this.isShowHtml;
    let text = this.quillEditor.root.innerHTML;
    this.massmailForm.controls['htmlBody'].setValue(text);
  }
  updateHtmlToEditor() {
    let text = this.massmailForm.controls['htmlBody'].value;
    this.massmailForm.controls['htmlBody'].setValue(text);
  }

  insertInstructions(): void {
    let msg: string = this.massmailForm.controls['htmlBody'].value;
    if (msg && msg.length > 0) {
      if (msg.match(this.instructionTitle)) {
        this.hasInstructions = true;
        return;
      }
      var msgWithInstructions = this.instructionTitle + msg;
      const selection = this.quillEditor.getSelection(true);
      this.quillEditor.insertText(selection.index, this.instructionTitle);
      let text = this.quillEditor.root.innerHTML;
      this.massmailForm.controls['htmlBody'].setValue(text);

    } else {
      msg = `${this.instructionTitle}`;
      this.massmailForm.controls['htmlBody'].setValue(msg);
    }
    this.hasInstructions = true;
  }

  removeInstructions(): void {
    let msg: string = this.massmailForm.controls['htmlBody'].value;
    if (msg && msg.match(this.instructionTitle)) {
      msg = msg.replace(this.instructionTitle, '');
    }

    this.massmailForm.controls['htmlBody'].setValue(msg);
    this.hasInstructions = false;
  }
}