<container-card>
  <div class="d-flex justify-content-around flex-wrap ">
    <div class="project-type-option">
      <div class="card type-option-container">
        <div class="card-body d-flex flex-column">
          <h2 class="card-title">Goal-Oriented</h2>
          <div class="project-type-description">
            <p class="card-text">Utilize best-practice assessment questions.</p>
            <ul>
              <li class="mb-3"><span>Select <b>pre-made questions</b> to incorporate into your survey</span></li>
              <li class="mb-3"><span>Be confident that your survey will <b>contribute to assessment initiatives.</b></span></li>
            </ul>
          </div>
          <button (click)="selectProjectType(createProjectUrl, projectTypeEnum.Goal)" class="btn btn-primary btn-block">Build Project</button>
        </div>
      </div>
    </div>
    <div class="project-type-option">
      <div class="card type-option-container">
        <div class="card-body d-flex flex-column">
          <h2 class="card-title">Assistance-Based</h2>
          <div class="project-type-description">
            <p class="card-text">Anthology experts will review your submitted survey details.</p>
            <ul>
              <li class="mb-3"><span>Lean on our <b>experienced survey administrators</b> to provide feedback.</span></li>
              <li class="mb-3"><span>We're also happy to perform a simple <b>grammar and formatting check.</b></span></li>
            </ul>
          </div>
          <button (click)="selectProjectType(requestProjectUrl)" class="btn btn-primary btn-block">Request Project</button>
        </div>
      </div>
    </div>
    <div class="project-type-option">
      <div class="card type-option-container">
        <div class="card-body d-flex flex-column">
          <h2 class="card-title">Free-Form</h2>
          <div class="project-type-description">
            <p class="card-text">Put your assessment expertise on display and manage all aspects.</p>
            <ul>
              <li class="mb-3"><span><b>Start from scratch</b> to create your own survey experience.</span></li>
              <li class="mb-3"><span>Use basic reporting with the ability to <b>export data.</b></span></li>
            </ul>
          </div>
          <button (click)="selectProjectType(createProjectUrl, projectTypeEnum.Form)" class="btn btn-primary btn-block">Create Project</button>
        </div>
      </div>
    </div>
  </div>
</container-card>