import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { MASSMAIL_ACTIONS } from '../../store/massmail/massmail.actions';
import { MASSMAIL_SELECTORS } from '../../store/massmail/massmail.reducer';
import { Observable } from 'rxjs';
import { IMassMailStatus } from '../../../shared/models/mass-mail-status';
import { IProjectEmailResult } from '../../../shared/models/project-email-result';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';


@Component({
  selector: 'copymail-form',
  templateUrl: './copymail-form.component.html',
  styleUrls: ['./copymail-form.component.scss'],
})
export class CopymailFormComponent implements OnInit {
  @Input() config: any;
  projectId: number;
  projects$: Observable<any>;
  massMailStatus$: Observable<IMassMailStatus>;
  emailResult$: Observable<IProjectEmailResult>;
  selectedEmailResult: IProjectEmailResult = null;
  copymailForm = new FormGroup(
    {
      subject: new FormControl<string>(''),
      name: new FormControl<string>(''),
      replyToAddress: new FormControl<string>(''),
      description: new FormControl<string>(''),
      selectedProjectId: new FormControl<number>(null),
      selectedMailing: new FormControl<number>(null),
      copyRecipients: new FormControl<boolean>(true),
    },
    {
      validators: [
      ],
    },
  );
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
  ) {
    this.route.paramMap.pipe(take(1)).subscribe(paramMap => {
      this.projectId = +paramMap.get('projectId');
    });
  }

  ngOnInit(): void {
    this.getProjects();
    this.copymailForm.get('selectedProjectId').setValue(this.projectId);
    this.selectProject();
  }

  getProjects() {
    this.store.dispatch(MASSMAIL_ACTIONS.getAllProjects());
    this.projects$ = this.store.pipe(select(MASSMAIL_SELECTORS.getAllProjects));
  }
  submitMassmail(): void { }

  selectProject(): void {
    const selectedProjectId = this.copymailForm.get('selectedProjectId').value;
    this.store.dispatch(MASSMAIL_ACTIONS.getMassMailByProjectId({ projectId: selectedProjectId }));
    this.massMailStatus$ = this.store.pipe(select(MASSMAIL_SELECTORS.getMassMail));
    this.copymailForm.get('selectedMailing').setValue(null);
    this.selectedEmailResult = null;
  }

  getEmailAddressFromMailing(): void {
    const selectedMailing = this.copymailForm.get('selectedMailing').value;
    const selectedProjectId = this.copymailForm.get('selectedProjectId').value;
    if (selectedProjectId && selectedMailing) {
      this.store.dispatch(MASSMAIL_ACTIONS.getMassMailByEmailId({ projectId: +selectedProjectId, emailId: +selectedMailing }));
      this.emailResult$ = this.store.select(MASSMAIL_SELECTORS.getMassMailByEmailId);
      this.emailResult$.subscribe((result: IProjectEmailResult) => {
        if (result.projectId == selectedProjectId && result.recipientList) {
          this.selectedEmailResult = result;
        }
      });
    }
  }

  close(): void {
    this.router.navigateByUrl(`/project/${this.projectId}/massmail`);
  }

  createMailing(): void {
    const copyProjectId: number = this.copymailForm.get('selectedProjectId').value;
    const copyEmailId: number = this.copymailForm.get('selectedMailing').value;
    const copyRecipients: boolean = this.copymailForm.get('copyRecipients').value;

    this.router.navigateByUrl(`/project/${this.projectId}/massmail/${copyProjectId}/${copyEmailId}/create?copyRecipients=${copyRecipients === true ? 'true' : 'false'}`);
  }
}
