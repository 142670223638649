import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { environment } from 'projects/surveys/src/environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { IOrganization } from '../../../shared/models/organization';
import { OrganizationHttpService } from '../../../shared/services/communication/organization-http.service';
import { MESSAGE_ACTIONS } from '../../../store/message/message.actions';
import { ORGANIZATION_ACTIONS } from './organization.action';

@Injectable()
export class OrganizationEffects {

  constructor(
    private actions$: Actions,
    private organizationHttpService: OrganizationHttpService,
  ) { }

  getOrganizations$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(ORGANIZATION_ACTIONS.getOrganizations),
      mergeMap(() =>
        this.organizationHttpService.getOrganizations().pipe(
          map((organizations: IOrganization[]) =>
            ORGANIZATION_ACTIONS.storeOrganizations({ payload: organizations }),
          ),
          catchError((errorResponse: HttpErrorResponse) => {
            window.location.assign(environment.AUTH_URL);
            // Dont pass the loaderType here because we want to keep the app loading until we redirect
            return of(MESSAGE_ACTIONS.danger({errorResponse}));
          }),
        ),
      ),
    ),
  );

}
