<h2>{{goal.title}}</h2>

<ngb-accordion>
  <ngb-panel [disabled]="true" cardClass="goal-instruction">
    <ng-template ngbPanelHeader>
      <span class="d-flex align-items-center p-2 bold">Select the dimension you'd like to measure:</span>
    </ng-template>
  </ngb-panel>

  <ngb-panel [disabled]="true">
    <ng-template ngbPanelHeader>
      <span class="d-flex align-items-center p-2 bold">
        <input type="checkbox" [id]="'selectAll-' + goal.templateGoalId" [checked]="(projectDimensions$ | async)?.size === goal.templateDimensions.length" (click)="toggleAll()">
        <label [for]="'selectAll-' + goal.templateGoalId" class="mb-0 ml-2">Select All Dimensions</label>
      </span>
    </ng-template>
  </ngb-panel>

  <ngb-panel *ngFor="let dimension of goal.templateDimensions" [id]="dimension.dimensionPanelId" cardClass="edit-goal-dimension-card">
    <ng-template ngbPanelHeader>
      <span class="d-flex align-items-center p-2 bold">
        <input type="checkbox" [id]="'dimension-' + dimension.templateDimensionId" [ngModel]="(projectDimensions$ | async)?.has(dimension.templateDimensionId)" (click)="clickDimension(dimension)">
        <label class="mb-0 ml-2" [for]="'dimension-' + dimension.templateDimensionId">{{dimension.description}}</label>
      </span>
    </ng-template>
    <ng-template ngbPanelContent *ngFor="let question of dimension.templateQuestions">
      <template-question *ngIf="(projectQuestionSeparation$ | async)?.get(question.templateQuestionId) as questionSeparation"
        [question]="question"
        [questionSeparation]="questionSeparation"
        [templateDimensionId]="dimension.templateDimensionId"
        [goal]="goal">
      </template-question>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
