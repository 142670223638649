import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByProjectName'
})
export class SortByProjectNamePipe implements PipeTransform {

  transform(value: any[], ...args: any[]): any[] {
    if (!value) return value;

    let copyValue= [...value];
    let sortedValue = copyValue.sort((a,b) => {
      if ( a.name < b.name){
        return -1;
      }
      if ( a.name> b.name){
        return 1;
      }
      return 0;
    })

    return sortedValue;
  }

}
