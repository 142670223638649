import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { IOrgUnit } from '../../../shared/models/org-unit';
import { OrganizationHttpService } from '../../../shared/services/communication/organization-http.service';
import { MESSAGE_ACTIONS } from '../../../store/message/message.actions';
import { ORGUNIT_ACTIONS } from './orgUnit.actions';

@Injectable()
export class OrgUnitEffects {

  constructor(
    private actions$: Actions,
    private organizationHttpService: OrganizationHttpService,
  ) { }

  getOrgUnits$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(ORGUNIT_ACTIONS.getOrgUnits),
      mergeMap(() =>
        this.organizationHttpService.getOrgUnits().pipe(
          map((orgUnits: IOrgUnit[]) =>
            ORGUNIT_ACTIONS.storeOrgUnits({ payload: orgUnits }),
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({errorResponse})),
          ),
        ),
      ),
    ),
  );

}
