import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MESSAGE_ACTIONS } from '../../store/message/message.actions';
import { MESSAGE_SELECTORS } from '../../store/message/message.reducer';
import { IMessage } from '../models/message';

@Component({
  selector: 'messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  message$: Observable<IMessage>;
  showMessage$: Observable<boolean>;

  constructor(private store: Store) { }

  onClose(messageId: number) : void {
    this.store.dispatch(MESSAGE_ACTIONS.hideMessage({messageId: messageId}));
  }

  ngOnInit(): void {
    this.message$ = this.store.pipe(select(MESSAGE_SELECTORS.getMessage));
    this.showMessage$ = this.store.pipe(select(MESSAGE_SELECTORS.getShowMessage));
  }

}