import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ICurrentUserAccount, INavigationItem } from '../../shared/models/account';
import { ACCOUNT_ACTIONS } from './account.actions';

export interface IAccountState {
  currentAccount: ICurrentUserAccount;
  navigationMenu: INavigationItem[];
}
export const initialState: IAccountState = {
  currentAccount: null,
  navigationMenu: [],
};

export const accountReducer = createReducer(
  initialState,
  on(ACCOUNT_ACTIONS.storeAccount, (state, { payload }) => {
    const currAcc: ICurrentUserAccount = { account: payload.account, institution: payload.institution };
    const currNav: INavigationItem[] = [].concat(payload.navigationMenu);

    // Ensure that the nav items are ordered by sequence
    currNav.sort((a,b) => a.sequence - b.sequence);
    return { ...state, currentAccount: currAcc, navigationMenu: currNav };
  }),
);

const getUsersState = createFeatureSelector<IAccountState>('account');
const getCurrentUserAccountFunc = (state: IAccountState) => state.currentAccount;
const getCurrentNavigationFunc = (state: IAccountState) => state.navigationMenu;

const getCurrentUserAccount = createSelector(
  getUsersState,
  getCurrentUserAccountFunc,
);

const getCurrentNavigation = createSelector(
  getUsersState,
  getCurrentNavigationFunc,
);

export const ACCOUNT_SELECTORS = {
  getCurrentUserAccount,
  getCurrentNavigation,
};
