import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { NxWordComponent } from "@aposin/ng-aquila/natural-language-form";
import { Store } from "@ngrx/store";
import { BaseSubscriptionComponent } from "projects/surveys/src/app/shared/BaseSubscriptionComponent";
import { ITemplateGoal, ITemplateQuestion, ITemplateQuestionSeparation } from "projects/surveys/src/app/shared/models/template-goal";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { PROJECT_ACTIONS } from "../../../../store/project/project.actions";

@Component({
  selector: 'template-question',
  templateUrl: './template-question.component.html',
  styleUrls: ['./template-question.component.scss'],
})
export class TemplateQuestionComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() question: ITemplateQuestion;
  @Input() questionSeparation: ITemplateQuestionSeparation[];
  @Input() templateDimensionId: number;
  @Input() goal: ITemplateGoal;
  @ViewChild(NxWordComponent) nxWord: NxWordComponent;

  projectForm = new FormGroup({});
  customInputIndex: number;
  localQuestionSeparation: ITemplateQuestionSeparation[];

  constructor(
    private store: Store,
  ) {
    super();
  }

  ngOnInit(): void {
    // this should never happen, but if for some reason we get here and questionSeparation is null, don't do anything else
    if (this.questionSeparation == null) {
      return;
    }

    // set local array to loop over in the template
    // if we watch state instead, the natural language form re-renders and causes style issues
    this.localQuestionSeparation = this.questionSeparation;

    // find the index of the custom input, and set up a form control for it
    this.customInputIndex = this.questionSeparation.findIndex(q => q.isInput);
    if (this.customInputIndex > -1) {
      const controlName = `customInput${this.question.templateQuestionId}${this.customInputIndex}`;
      this.projectForm.addControl(controlName, new FormControl());
      this.projectForm.controls[controlName].setValue(this.questionSeparation[this.customInputIndex].text);

      // watch value changes in the custom input and update state accordingly
      this.projectForm.controls[controlName].valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((newText: string) => {
          // make a copy to pass into state so the view doesn't reset
          const updatedQuestionSeparation = [...this.questionSeparation];
          updatedQuestionSeparation[this.customInputIndex] = {
            ...this.questionSeparation[this.customInputIndex],
            text: newText
          };

          this.updateText(this.templateDimensionId, this.question.templateQuestionId, updatedQuestionSeparation);
        });
    }
  }

  ngAfterViewInit() {
    // when initially loading the page, we need to manually set the width of the custom input border
    // otherwise, it is overriden with default width styles from natural language form
    if (this.customInputIndex > -1) {
      const width = this.localQuestionSeparation[this.customInputIndex].text.length * 8.5;
      this.nxWord.elementRef.nativeElement.style.width = `${width}px`;
    }
  }

  updateText(templateDimensionId: number, templateQuestionId: number, questionTextSeparation: ITemplateQuestionSeparation[]) {
    this.store.dispatch(PROJECT_ACTIONS.updateQuestionText({
      templateLookUp: {
        templateGoalId: this.goal.templateGoalId,
        templateDimensionId,
        templateQuestionId,
      },
      questionTextSeparation,
    }));
  }

}
