import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProjectType } from '../../shared/enums/project-type';
import { IProjectRequest } from '../../shared/models/project';
import { PROJECT_ACTIONS } from '../store/project/project.actions';
import { PROJECT_SELECTORS } from '../store/project/project.reducer';

@Component({
  selector: 'survey-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent implements OnInit {
  projectType$: Observable<ProjectType>;
  projectTypeEnum = ProjectType;

  constructor(
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    // get the selected project type, used in the template
    this.projectType$ = this.store.select(PROJECT_SELECTORS.getProjectType);
  }

  submitProject(newProject: IProjectRequest): void {
    this.store.dispatch(PROJECT_ACTIONS.createProject({ payload: newProject }));
  }
}
