import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { BaseSubscriptionComponent } from '../../../shared/BaseSubscriptionComponent';
import { IProject, IProjectGoalRequest } from '../../../shared/models/project';
import { ITemplateGoal } from '../../../shared/models/template-goal';
import { TEMPLATE_GOAL_ACTIONS } from '../../../store/template-goal/template-goal.actions';
import { TEMPLATE_GOAL_SELECTORS } from '../../../store/template-goal/template-goal.reducer';
import { PROJECT_ACTIONS } from '../../store/project/project.actions';
import { PROJECT_SELECTORS } from '../../store/project/project.reducer';

@Component({
  selector: 'app-edit-project-goal',
  templateUrl: './edit-project-goal.component.html',
  styleUrls: ['./edit-project-goal.component.scss'],
})
export class EditProjectGoalComponent extends BaseSubscriptionComponent implements OnInit {
  templateGoalMap$: Observable<Map<number, ITemplateGoal>>;
  templateGoals$: Observable<ITemplateGoal[]>;
  projectGoalIds$: Observable<number[]>;
  projectGoalId$: Observable<number>;
  project$: Observable<IProject>;
  projectGoalRequest$: Observable<IProjectGoalRequest[]>;
  instructions$: Observable<string>;
  customInputError$: Observable<boolean>;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
  ) {
    super();

  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe(params => {
      this.store.dispatch(PROJECT_ACTIONS.getProjectWithGoals({ payload: params['projectId'] }));
      this.project$ = this.store.pipe(select(PROJECT_SELECTORS.getProject));
      this.projectGoalIds$ = this.store.pipe(select(PROJECT_SELECTORS.getProjectGoalIds));
      this.projectGoalId$ = this.store.pipe(select(PROJECT_SELECTORS.getProjectGoalIds)).pipe(map(ids => {
        return ids ? ids[0] : null;
      }));
    });

    // Request all goals with their dimensions.
    this.store.dispatch(TEMPLATE_GOAL_ACTIONS.getTemplateGoals());

    this.projectGoalRequest$ = this.store.select(PROJECT_SELECTORS.getProjectGoalRequest);
    this.templateGoalMap$ = this.store.pipe(select(TEMPLATE_GOAL_SELECTORS.getTemplateGoalMap));
    this.templateGoals$ = this.store.pipe(select(TEMPLATE_GOAL_SELECTORS.getTemplateGoals));
    this.instructions$ = this.store.select(PROJECT_SELECTORS.getInstructions);
    this.customInputError$ = this.store.select(PROJECT_SELECTORS.getHasCustomInputError);
  }

  cancel(): void {
    history.back();
  }

  changeGoal(goalId: string): void {
    this.templateGoalMap$.pipe(take(1)).subscribe(goalMap => {
      this.store.dispatch(PROJECT_ACTIONS.storeGoalOnProject({ payload: goalMap.get(parseInt(goalId)) }));
    });
  }

  onInstructionsUpdate(instructions: string): void {
    this.store.dispatch(PROJECT_ACTIONS.storeInstructions({ payload: instructions }));
  }

  saveGoals(): void {
    this.projectGoalRequest$.pipe(
      withLatestFrom(this.project$),
      withLatestFrom(this.instructions$),
      take(1),
    ).subscribe(([[goalReqs, project], instructions]: [[IProjectGoalRequest[], IProject], string]) => {
      this.store.dispatch(PROJECT_ACTIONS.postProjectGoals({ projectId: project.projectId, projectGoalRequests: goalReqs, instructions }));
    });
  }
}
