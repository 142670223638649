<form [formGroup]="massmailForm" (ngSubmit)="submitMassmail()">
  <div class="form-group col-sm-6">
    <label for="fromName" class="addresses-label">To<span class="ml-1 required">*</span></label>
    <button class="btn add-addresses box-shadow d-inline-block" (click)="openModal(modal)">Add Addresses </button>
    <span *ngIf="!recipientList?.value?.length || recipientList?.value?.length <= 1" [ngClass]="{'required': recipientList?.errors?.required}">{{recipientList?.value?.length || 0}} Recipient</span>
    <span *ngIf="recipientList?.value?.length > 1" [ngClass]="{'required': recipientList?.errors?.required}">{{recipientList?.value?.length}} Recipients</span>
    <span *ngIf="config.state.toLowerCase() == 'sent'" class="fw-bold messages-sent">({{recipientList?.value?.length}} Messages Sent)</span>
  </div>
  <div class="form-group col-sm-6">
    <label for="fromName">From Name<span class="ml-1 required">*</span></label>
    <input type="text" class="form-control" [attr.disabled]="isFormFieldDisabled ? 'disabled' : null"
      formControlName="from" id="fromName" required>
    <div class="required" *ngIf="from.touched && from?.errors?.required">
      Name required
    </div>
  </div>
  <div class="form-group  col-sm-6">
    <label for="replyToAddress">Reply-To Address<span class="ml-1 required">*</span></label>
    <input type="text" class="form-control" [attr.disabled]="isFormFieldDisabled ? 'disabled' : null"
      formControlName="replyTo" id="replyTo" required>
    <div class="required" *ngIf="replyTo.touched && replyTo?.errors?.required">
      Email required
    </div>
    <div class="required" *ngIf="replyTo.touched && replyTo?.errors?.email">
      Invalid email
    </div>
  </div>
  <div class="form-group col-sm-12">
    <label for="subject">Subject<span class="ml-1 required">*</span></label>
    <input type="text" class="form-control" [attr.disabled]="isFormFieldDisabled ? 'disabled' : null"
      formControlName="subject" id="subject" required>
    <div class="required" *ngIf="subject.touched && subject?.errors?.required">
      Subject required
    </div>
  </div>
  <div class="form-group col-sm-12">
    <div class="form-check">
      <input class="form-check-input" type="radio" [attr.disabled]="isFormFieldDisabled ? 'disabled' : null"
        name="scheduledOrSendImmediately" formControlName="scheduledOrSendImmediately" value="sendImmediate"
        id="scheduledOrSendImmediately2" (change)="handleChange($event)">
      <label class="form-check-label" for="scheduledOrSendImmediately2">
        Send Email Immediately
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" [attr.disabled]="isFormFieldDisabled ? 'disabled' : null"
        name="scheduledOrSendImmediately" formControlName="scheduledOrSendImmediately" value="schedule"
        id="scheduledOrSendImmediately1" (change)="handleChange($event)">
      <label class="form-check-label" for="scheduledOrSendImmediately1">
        Schedule Email
      </label>
    </div>
  </div>
  <div class="form-group col-sm-12" *ngIf="massmailForm.controls['scheduledOrSendImmediately'].value == 'schedule'">
    <div class="col-sm-6 d-inline-block pl-0">
      <app-date [selectedDate]="massmailForm.controls['scheduledSendDate'].value" [isDisabled]="isFormFieldDisabled"
        (onDateChange)="setScheduledSendDate($event)"></app-date>
    </div>
    <div class="col-sm-5 d-inline-block pl-0" style="padding-left:.5em;">
      <app-time [selectedDateTime]="massmailForm.controls['scheduledSendTime'].value" [isDisabled]="isFormFieldDisabled"
        (onTimeChange)="setScheduledSendTime($event)"></app-time>
    </div>
    <div class="col-sm-1 d-inline-block pl-0" style="padding-left:.5em;">
      {{this.timezone}}
    </div>
    <br>
    <div class="required" *ngIf="scheduleDateTime.touched && scheduleDateTime?.errors?.required">
      Send Date required
    </div>
    <div class="required" *ngIf="scheduleDateTime?.errors?.invalidDate">
      Send Date cannot be in the past.
    </div>
  </div>
  <div class="required" *ngIf="scheduleDateTime?.errors?.projectDate">
    Send Date outside of Project dates.
  </div>
  <div class="form-group col-sm-12">
    <label for="subject">Description (optional)</label>
    <input type="text" class="form-control" [attr.disabled]="isFormFieldDisabled ? 'disabled' : null"
      formControlName="description" id="description">
  </div>
  <div class="form-group col-sm-12 mt-2">
    <div class="message-instructions">
      <label class="msg-lbl d-inline-block" for="message">Message</label>
      <button *ngIf="!isInstructionAdded()" [attr.disabled]="isFormFieldDisabled ? 'disabled' : null"
        class="btn instruction-btn light-blue-btn box-shadow d-inline-block" (click)="insertInstructions()">Insert
        Instructions</button>
      <button *ngIf="isInstructionAdded()" [attr.disabled]="isFormFieldDisabled ? 'disabled' : null"
        class="btn instruction-btn light-blue-btn box-shadow d-inline-block" (click)="removeInstructions()">Remove
        Instructions</button>
        <button  [attr.disabled]="isFormFieldDisabled ? 'disabled' : null" class="btn instruction-btn light-blue-btn box-shadow d-inline-block" (click)="onChangeShowHideHtml()">
          {{!isShowHtml?'Edit': 'Hide'}} Html
        </button>
    </div>
    <div [hidden]="!isShowHtml">
      <textarea formControlName="htmlBody" class="w-100" rows="8" (change)="updateHtmlToEditor()"></textarea>
    </div>
  <quill-editor (onEditorCreated)="created($event)" [hidden]="isShowHtml" [styles]="{height: '200px'}" [modules]="options" formControlName="htmlBody"></quill-editor>
    </div>
  <div class="form-group col-sm-12 mt-2"
    *ngIf="config.state.toLowerCase() != 'scheduled' && config.state.toLowerCase() != 'sent'">
    <div class="col-sm-6 d-inline-block pl-0 text-left">
      <button (click)="openPreviewModal(previewModal)"
        class="btn preview-btn light-blue-btn box-shadow d-inline-block">Preview</button>
    </div>
    <div class="col-sm-6 d-inline-block pl-0 pr-0 text-right">
      <button class="btn btn-link" type="button" (click)="close()">Cancel</button>
      <button [disabled]="subject?.errors"
        class="btn save-close-btn light-blue-btn box-shadow d-inline-block" (click)="saveClose($event)">Save &
        Close</button>
      <button [disabled]="form?.invalid"
        *ngIf="isInstructionAdded() && massmailForm.controls['scheduledOrSendImmediately'].value == 'sendImmediate'"
        class="btn approve-btn dark-blue-btn box-shadow d-inline-block" (click)="schedule()">Send Now</button>
      <button [disabled]="form?.invalid"
        *ngIf="!isInstructionAdded() && massmailForm.controls['scheduledOrSendImmediately'].value == 'sendImmediate'"
        class="btn approve-btn dark-blue-btn box-shadow d-inline-block" 
        (click)="openScheduleConfirmationModal(confirmScheduleModal)">Send Now</button>
      <button [disabled]="form?.invalid"
        *ngIf="isInstructionAdded() && massmailForm.controls['scheduledOrSendImmediately'].value == 'schedule'"
        class="btn approve-btn dark-blue-btn box-shadow d-inline-block" (click)="schedule()">Schedule</button>
      <button [disabled]="form?.invalid"
        *ngIf="!isInstructionAdded() && massmailForm.controls['scheduledOrSendImmediately'].value == 'schedule'"
        class="btn approve-btn dark-blue-btn box-shadow d-inline-block"
        (click)="openScheduleConfirmationModal(confirmScheduleModal)">Schedule</button>
    </div>
  </div>
  <div class="form-group col-sm-12 mt-2" *ngIf="config.state.toLowerCase() == 'scheduled'">
    <div class="col-sm-6 d-inline-block pl-0 text-left">
      <button class="btn delete-btn light-blue-btn box-shadow d-inline-block" (click)="unschedule()">Set as
        Draft</button>
      <button (click)="openPreviewModal(previewModal)"
        class="btn preview-btn light-blue-btn box-shadow d-inline-block">Preview</button>
    </div>
    <div class="col-sm-6 d-inline-block pl-0 pr-0 text-right">
      <button class="btn btn-link" type="button" (click)="close()">Close</button>
    </div>
  </div>
  <div class="form-group col-sm-12 mt-2" *ngIf="config.state.toLowerCase() == 'sent'">
    <respondent-details [respondentDetails]="formData.respondentDetails" [emailId]="config.emailId"></respondent-details>
    <div class="col-sm-6 d-inline-block pl-0 text-left">
    </div>
    <div class="col-sm-6 d-inline-block pl-0 pr-0 text-right">
      <button class="btn close-btn light-blue-btn box-shadow d-inline-block mr-0" (click)="close()">Close</button>
    </div>
  </div>
</form>

<div>
  <ng-template #modal let-modal>
    <div class="modal-header align-items-center">
      <h4 class="modal-title">Send Mailing To:</h4>
      <span class="p-2 close-modal-icon" (click)="modal.dismiss()"><i class="bi bi-x"></i></span>
    </div>
    <div class="modal-body">
      <address-form [projectId]="projectId" [isDisabled]="isAddEmailAddressDisabled" [isRemoveEmailDisabled]="isRemoveEmailAaddressDisabled "
        [recipientList]="massmailForm.controls['recipientList'].value"
        (onRecipientListChange)="setRecipientsList($event)"></address-form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.dismiss()">Close</button>
    </div>
  </ng-template>
</div>

<div>
  <ng-template #previewModal let-modal>
    <div class="modal-header align-items-center">
      <h4 class="modal-title">Preview Mailing</h4>
      <span class="p-2 close-modal-icon" (click)="modal.dismiss()"><i class="bi bi-x"></i></span>
    </div>
    <div class="modal-body">
      <div class="form-group col-sm-12">
        <label for="from">From : <span>{{massmailForm.controls['from'].value}}</span></label>
      </div>
      <div class="form-group col-sm-12">
        <label for="subject">Subject : <span>{{massmailForm.controls['subject'].value}}</span></label>
      </div>
      <div class="form-group col-sm-12">
        <label for="reply">Reply : <span><a
              [attr.href]="'mailto://'+ massmailForm.controls['replyTo'].value ">{{massmailForm.controls['replyTo'].value}}</a></span></label>
      </div>
      <div class="form-group col-sm-12" *ngIf="msgWithInstructions">
        <div class="email-preview-body" [innerHtml]="msgWithInstructions | safeHtml"></div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.dismiss()">Close</button>
    </div>
  </ng-template>
</div>

<div>
  <ng-template #confirmScheduleModal let-modal>
    <div class="modal-header align-items-center">
      <h4 class="modal-title">Confirm Message</h4>
      <span class="p-2 close-modal-icon" (click)="modal.dismiss()"><i class="bi bi-x"></i></span>
    </div>
    <div class="modal-body">
      <p>The instruction tag is missing from the message body. Are you sure you want to send without instructions?
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.dismiss()">Cancel</button>
      <button type="button" class="btn btn-danger" (click)="schedule();modal.dismiss()">{{massmailForm.controls['scheduledOrSendImmediately']?.value == 'schedule' ? 'Schedule' : 'Send'}}</button>
    </div>
  </ng-template>
</div>
