import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/surveys/src/environments/environment';
import { Observable } from 'rxjs';
import { IProject, IProjectGoalUpdateRequest, IProjectRequest, IProjectWithGoalsResponse } from '../../models/project';
import { BaseHttpService } from './base-http.service';

@Injectable({providedIn: 'root'})
export class ProjectHttpService extends BaseHttpService {
  constructor(h: HttpClient) {
    super(h);
  }

  private _buildUrl(append = ''): string {
    return `${environment.BASELINE_API}/surveys/projects/${append}`;
  }

  createProject(project: IProjectRequest): Observable<IProject> {
    return this.postRequest({
      url: this._buildUrl(),
      body: project,
      query: null,
    });
  }

  updateProject(projectId: string, project: IProjectRequest): Observable<IProject> {
    return this.patchRequest({
      url: this._buildUrl(projectId),
      body: project,
      query: null,
    });
  }

  deleteProjects(organizationId: string, projectIdList: string[]): Observable<boolean> {
    return this.postRequest({
      url: this._buildUrl(`bulk-delete/${organizationId}`),
      body: projectIdList,
      query: null,
    });
  }

  isLinkAvailable(genericLink: string, projectId?: number): Observable<boolean> {
    return this.getRequest({
      url: this._buildUrl('is-link-available'),
      query: { genericLink, projectId },
    });
  }

  getProjectById(projectId: string): Observable<IProject> {
    return this.getRequest({
      url: this._buildUrl(projectId),
      query: {},
    });
  }

  getProjectWithGoals(projectId: string): Observable<IProjectWithGoalsResponse> {
    return this.getRequest({
      url: this._buildUrl(projectId),
      query: {},
    });
  }

  patchProjectGoals(projectId: number, projectGoalUpdateRequest: IProjectGoalUpdateRequest): Observable<IProjectWithGoalsResponse> {
    return this.patchRequest({
      url: this._buildUrl(`${projectId}/goals`),
      body: projectGoalUpdateRequest,
      query: {},
    });
  }

}
