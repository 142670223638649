<container-card>
  <p class="text-danger italic">This will delete all Projects and related Benchmark Views</p>

  <form [formGroup]="bulkDeleteForm" (ngSubmit)="openModal(modal)" class="d-flex flex-column align-items-center">
    <div class="form-group width-60 my-4">
      <label for="organization">Select the Organization you want to delete projects from<span class="ml-1 required">*</span></label>
      <select class="form-control" id="organization" formControlName="organization" required>
        <option *ngFor="let org of (organizations$ | async)" [ngValue]="org.organizationId">{{org.name}}</option>
      </select>
    </div>
    <div class="form-group width-60 my-4">
      <label for="projectIds">Comma separated list of Project Id's<span class="ml-1 required">*</span></label>
      <textarea type="text" class="form-control ta" formControlName="projectIds" id="projectIds" required></textarea>
    </div>

    <div class="d-flex justify-content-end mt-4">
      <button class="btn btn-link" type="button" (click)="cancel()">Back</button>
      <button class="btn btn-danger box-shadow" [disabled]="!bulkDeleteForm.valid" type="submit">Delete</button>
    </div>
  </form>

</container-card>

<div>
  <ng-template #modal let-modal>
    <div class="modal-header align-items-center">
      <h4 class="modal-title">Confirm Delete</h4>
      <span class="p-2 close-modal-icon" (click)="modal.dismiss()"><i class="bi bi-x"></i></span>
    </div>
    <div class="modal-body">
      <p>All <b>Projects</b> and related <b>Benchmark Views</b> will be deleted. This is a permanent action that cannot be undone.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.dismiss()">Cancel</button>
      <button type="button" class="btn btn-danger" (click)="deleteProjects()">Delete</button>
    </div>
  </ng-template>
</div>