import { createAction, props } from '@ngrx/store';
import { IProjectEmailResult } from '../../../shared/models/project-email-result';

const createProjectEmail = createAction('MASSMAIL-saveProjectEmail', props<{ projectId: number, massmailform: any; }>());
const updateProjectEmail = createAction('MASSMAIL-updateProjectEmail', props<{ projectId: number, emailId: number, massmailform: any; }>());
const scheduleProjectEmail = createAction('MASSMAIL-scheduleProjectEmail', props<{ projectId: number, emailId: number, massmailform: any; }>());
const unscheduleProjectEmail = createAction('MASSMAIL-unscheduleProjectEmail', props<{ projectId: number, emailId: number, massmailform: any; }>());
const storeProjectEmail = createAction('MASSMAIL-storeProjectEmail', props<{ email: IProjectEmailResult; }>());

export const MASSMAILFORM_ACTIONS = {
  createProjectEmail,
  updateProjectEmail,
  scheduleProjectEmail,
  unscheduleProjectEmail,
  storeProjectEmail,
};
