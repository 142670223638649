import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'projects/surveys/src/environments/environment';

export interface IHttpPayload {
  query: any;
  url: string;
  body: any | string;
  headers?: any;
  reportProgress?: boolean;
  observe?: 'response';
}

@Injectable({ providedIn: 'root' })
export abstract class BaseHttpService {
  constructor(
    private http: HttpClient,
  ) { }

  protected getRequest<T>(data: Partial<IHttpPayload>): Observable<T> {
    const params = this.createSearchParams(data.query);
    const headers = this.addHeaders(data.headers);
    return this.http.get<T>(
      data.url,
      { params, headers },
    ).pipe(catchError(this.handleError));
  }

  protected postRequest<T>(data: IHttpPayload): Observable<T> {
    const params = this.createSearchParams(data.query);
    const headers = this.addHeaders(data.headers);
    const reportProgress = data.reportProgress || false;
    return this.http.post<T>(
      data.url,
      data.body,
      { params, headers, reportProgress },
    ).pipe(catchError(this.handleError));
  }

  protected patchRequest<T>(data: IHttpPayload): Observable<T> {
    const params = this.createSearchParams(data.query);
    const headers = this.addHeaders(data.headers);
    return this.http.patch<T>(
      data.url,
      data.body,
      { params, headers },
    ).pipe(catchError(this.handleError));
  }

  protected deleteRequest<T>(data: any): Observable<T> {
    const params = this.createSearchParams(data.query);
    const headers = this.addHeaders(data.headers);
    return this.http.delete<T>(
      data.url,
      { params, headers },
    ).pipe(catchError(this.handleError));
  }

  private handleError(error: Response): Observable<never> {
    // If we get a 401 back that means the user does not have a auth cookie.
    // So we redirect to the sign in page.
    if (error.status === 401) {
      window.location.href = environment.AUTH_URL;
    }
    // return the error, so place that called can handle
    return throwError(() => error);
  }

  private createSearchParams(payload: any): HttpParams {
    let params: HttpParams = new HttpParams();
    if (!payload) { return params; }
    const _keys = Object.keys(payload);
    _keys.forEach((key) => {
      if (Array.isArray(payload[key])) {
        payload[key].forEach(element => {
          params = params.append(key, element);
        });
      } else {
        params = (payload[key] !== null && payload[key] !== undefined)
          ? params.append(key, payload[key])
          : params; // don't add null values
      }
    });
    return params;
  }
  private addHeaders(headers: any): HttpHeaders {
    return new HttpHeaders(headers);
  }
}
