import { Injectable } from '@angular/core';
import { environment } from 'projects/surveys/src/environments/environment';

@Injectable({providedIn: 'root'})
export class UrlConstantsService {
  constructor() { }

  // project request form
  getRequestProjectUrl(): string { return '/app/ClientWeb/RequestProject.aspx'; }

  // free-form or goal-oriented project form
  getCreateProjectUrl(): string { return '/project/create'; }

  // Legacy Builder page in Baseline proper
  getOldProjectBuilderUrl(projectId: string): string { return `${environment.OLD_BUILDER_URL}?ProjectId=${projectId}`; }

  // Legacy Settings page in Baseline proper
  getOldProjectSettingsUrl(projectId: string): string { return `${environment.OLD_SETTINGS_URL}?ProjectId=${projectId}&backToBaselineFe=True`; }
}
