import { createAction, props } from '@ngrx/store';
import { IMassMailStatus } from '../../../shared/models/mass-mail-status';
import { IProjectEmailResult } from '../../../shared/models/project-email-result';

const getMassMailByProjectId = createAction('MASSMAIL-getMassMailByProjectId', props<{ projectId: number; }>());
const storeMassMail = createAction('MASSMAIL-storeMassMail', props<{ massMail: IMassMailStatus; }>());
const getMassMailByEmailId = createAction('MASSMAIL-getMassMailByEmailId', props<{ projectId: number, emailId: number; }>());
const getMassMailReminderByEmailId = createAction('MASSMAIL-getMassMailReminderByEmailId', props<{ projectId: number, emailId: number; }>());
const storeMassMailByEmailId = createAction('MASSMAIL-storeMassMailByEmailId', props<{ editMassMail: any; }>());
const deleteMassmailByProjectId = createAction('MASSMAIL-deleteMassmailByProjectId', props<{ projectId: number, emailId: number; }>());
const getProjectByProjectId = createAction('MASSMAIL-getProjectByProjectId', props<{ projectId: number; }>());
const getPanelsByProjectId = createAction('MASSMAIL-getPanelsByProjectId', props<{ projectId: number; }>());
const storeProject = createAction('MASSMAIL-storeProject', props<{ project: any; }>());
const storePanels = createAction('MASSMAIL-storePanels', props<{ panels: any; }>());
const getAllProjects = createAction('MASSMAIL-getAllProjects');
const storeAllProjects = createAction('MASSMAIL-storeAllProjects', props<{ projects: any; }>());
const getRecipientListByPanelId = createAction('MASSMAIL-getRecipientListByPanelId', props<{ projectId: number, panelId: number; }>());
const storeRecipientList = createAction('MASSMAIL-storeRecipientList', props<{ recipientList: any; }>());
const getPreviewMailingFormat = createAction('MASSMAIL-getPreviewMailingFormat', props<{ projectId: number, emailId: number, massmailform: any; }>());
const storePreviewMailingFormat = createAction('MASSMAIL-storePreviewMailingFormat', props<{ emailResult: IProjectEmailResult; }>());
const downloadUndeliveredFile = createAction('MASSMAIL-downloadUndeliveredFile', props<{ projectId: number, emailId: number; }>());
const storeUndeliveredFile = createAction('MASSMAIL-storeUndeliveredFile', props<{ undeliveredFile: ArrayBuffer; }>());

export const MASSMAIL_ACTIONS = {
  getMassMailByProjectId,
  storeMassMail,
  getMassMailByEmailId,
  getMassMailReminderByEmailId,
  storeMassMailByEmailId,
  deleteMassmailByProjectId,
  getProjectByProjectId,
  getPanelsByProjectId,
  storeProject,
  storePanels,
  getAllProjects,
  storeAllProjects,
  getRecipientListByPanelId,
  storeRecipientList,
  getPreviewMailingFormat,
  storePreviewMailingFormat,
  downloadUndeliveredFile,
  storeUndeliveredFile,
};
