import { Component, Input } from '@angular/core';
import { IRespondentDetails } from '../../shared/models/project-email-result';

@Component({
  selector: 'respondent-details',
  templateUrl: './respondent-details.component.html',
  styleUrls: ['./respondent-details.component.scss']
})
export class RespondentDetailsComponent {
  @Input() respondentDetails: IRespondentDetails;
  @Input() emailId: number;

  goToRespondentDetailsPage(tab = 'home'): string {
    let origin = window.location.origin;
    if (origin === 'https://localhost:4200') {
      origin = 'https://localhost';
    }
    if (tab == 'answer'){
      return `${origin}/app/ClientWeb/Mailing/Recipients.aspx?type=OneAnswer&emailid=${this.emailId}`;
    } else if (tab == 'click'){
      return `${origin}/app/ClientWeb/Mailing/Recipients.aspx?type=ClickThrough&emailid=${this.emailId}`;
    } else
      return `${origin}/app/ClientWeb/Mailing/Recipients.aspx?type=NotResponded&emailid=${this.emailId}`;
  }
}
