import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { IProject, IProjectRequest } from '../../../shared/models/project';
import { UrlConstantsService } from '../../../shared/services/utils/url-constants.service';
import { PROJECT_ACTIONS } from '../../store/project/project.actions';
import { PROJECT_SELECTORS } from '../../store/project/project.reducer';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
})
export class EditProjectComponent implements OnInit {
  editProject$: Observable<IProject>;
  oldBuilderUrl: string;
  oldSettingsUrl: string;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private urlConstantsService: UrlConstantsService,
  ) {
    this.route.paramMap.pipe(take(1)).subscribe(paramMap => {
      const projectId = paramMap.get('projectId');
      this.store.dispatch(PROJECT_ACTIONS.getProjectById({ projectId: projectId }));
      this.oldBuilderUrl = this.urlConstantsService.getOldProjectBuilderUrl(projectId);
      this.oldSettingsUrl = this.urlConstantsService.getOldProjectSettingsUrl(projectId);
    });
  }

  ngOnInit(): void {
    this.editProject$ = this.store.select(PROJECT_SELECTORS.getProject);
  }

  submitProject(newProject: IProjectRequest): void {
    this.editProject$.pipe(take(1)).subscribe((editProj) => {
      this.store.dispatch(PROJECT_ACTIONS.storeEditProject({ projectId: editProj.projectId.toString(), projectReq: newProject }));
    });
  }
}
