import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IOrgUnit } from '../../../shared/models/org-unit';
import { ORGUNIT_ACTIONS } from './orgUnit.actions';

export interface IOrgUnitState {
  orgUnits: IOrgUnit[],
}

export const initialState: IOrgUnitState = {orgUnits: []};

export const orgUnitReducer = createReducer(
  initialState,

  on(ORGUNIT_ACTIONS.storeOrgUnits, (state, { payload }) => {
    return {orgUnits: payload};
  }),

);


const getState = createFeatureSelector<IOrgUnitState>('orgUnit');

const getOrgUnitsFunc = (state: IOrgUnitState) => state.orgUnits;

const getOrgUnits = createSelector(
  getState,
  getOrgUnitsFunc,
);

export const ORGUNIT_SELECTORS = {getOrgUnits};
