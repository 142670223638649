import { Component, Input } from '@angular/core';
import * as moment from 'moment';

export interface IGeneralInfo {
  state: string;
  action: string;
  project: IProject;
  scheduledSendDate?: string;
  actualSendDate?: string;
  mailCreatedBy?: string;
}

export interface IProject {
  name: string;
  createdByUsername: string;
  dateCreatedEastern: string;
  closeDateEastern: string;
}

@Component({
  selector: 'general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
})
export class GeneralInfoComponent {
  @Input() generalInfo: IGeneralInfo;

  ngOnInit(): void {
  }

  getDateTimeStr(date: string): string {
    if (!date) { return ''; }
    const dateStr = moment(date).format('LLL');
    return dateStr;
  }
}
