<div class="d-flex flex-column mt-3">
  <div class="container card">
    <div class="card-body">
      <h2 class="card-title mt-3 mb-3">{{((projectType$ | async) === projectTypeEnum.Goal) ? 'Create Goal-Oriented Project' : 'Create Project'}}</h2>
      <survey-project-form 
        (saveProject)="submitProject($event)">
      </survey-project-form>
    </div>
  </div>
</div>
