<div *ngIf="(userSettingsService.currentAccount$ | async)">
  <lib-topbar [setUserInfo]="topbarConfig.userInfo" [setUrls]="topbarConfig.urls"
    [setProductInfo]="topbarConfig.productInfo" [setSignOutFn]="topbarConfig.signOutFn"
    [setHelpFn]="topbarConfig.helpFn"></lib-topbar>

  <nav class="navbar navbar-light main-nav">
    <ul class="navbar-nav">
      <li class="nav-item" *ngFor="let link of (userSettingsService.currentNavigation$ | async)"
        [routerLinkActive]="['active']">
        <a class="nav-link" *ngIf="!link.newTab" [ngClass]="{active: link.name === 'Projects'}"
          [href]="link.url">{{link.name}}</a>
        <a class="nav-link" *ngIf="link.newTab" target="_blank" [ngClass]="{active: link.name === 'Projects'}"
          [href]="link.url">{{link.name}}</a>
      </li>
    </ul>
  </nav>

  <router-outlet></router-outlet>
</div>
<footer class="footer-content">
  <div id="copyright">
      © {{year}} Anthology Inc. and its affiliates. All rights reserved.
  </div>
</footer>
<messages></messages>
<loader></loader>
