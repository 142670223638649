import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'container-card',
  templateUrl: './container-card.component.html',
  styleUrls: ['./container-card.component.scss'],
})
export class ContainerCardComponent implements OnInit {
  @Input() pageTitle = '';

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.pageTitle = this.pageTitle ? this.pageTitle : this.route.snapshot.data['name'];

  }

}
