import { NgModule } from '@angular/core';
import { NgbAccordionModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { CreateProjectComponent } from './create-project/create-project.component';
import { EditProjectGoalComponent } from './edit/edit-project-goal/edit-project-goal.component';
import { ProjectsRoutingModule } from './projects-routing.module';
import { SelectProjectTypeComponent } from './select-type/select-project-type.component';
import { OrgUnitEffects } from './store/orgUnit/orgUnit.effect';
import { orgUnitReducer } from './store/orgUnit/orgUnit.reducer';
import { ProjectEffects } from './store/project/project.effect';
import { MassmailEffects } from './store/massmail/massmail.effect';
import { projectReducer } from './store/project/project.reducer';
import { massmailReducer } from './store/massmail/massmail.reducer';
import { EditGoalAccordionComponent } from './edit/edit-project-goal/edit-goal-accordion/edit-goal-accordion.component';
import { TemplateGoalEffects } from '../store/template-goal/template-goal.effects';
import { templateGoalReducer } from '../store/template-goal/template-goal.reducer';
import { NxNaturalLanguageFormModule } from '@aposin/ng-aquila/natural-language-form';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxPopoverModule } from '@aposin/ng-aquila/popover';
import { TemplateQuestionComponent } from './edit/edit-project-goal/edit-goal-accordion/template-question/template-question.component';
import { EditProjectComponent } from './edit/edit-project/edit-project.component';
import { ProjectFormComponent } from './shared/project-form/project-form.component';
import { BulkDeleteProjectsComponent } from './bulk-delete-projects/bulk-delete-projects.component';
import { organizationReducer } from './store/organization/organization.reducer';
import { OrganizationEffects } from './store/organization/organization.effect';
import { MassmailComponent } from './massmail/massmail.component';
import { MassmaildetailsComponent } from './massmaildetails/massmaildetails.component';
import { MassmailFormComponent } from './shared/massmail-form/massmail-form.component';
import { MassmailDateToSendFormComponent } from './shared/massmail-date-to-send-form/massmail-date-to-send-form.component';
import { DateComponent } from './shared/date/date.component';
import { TimeComponent } from './shared/time/time.component';
import { CopymailFormComponent } from './shared/copymail-form/copymail-form.component';
import { MassmailFormEffects } from './store/massmailform/massmailform.effect';
import { AddressFormComponent } from './shared/address-form/address-form.component';
import { AddressListComponent } from './shared/address-list/address-list.component';
import { SafeHtmlPipe } from './shared/massmail-form/safe-html.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { QuillModule } from 'ngx-quill';
import { SortByProjectNamePipe } from './shared/copymail-form/sort-by-project-name.pipe';
import { SortPipe } from './shared/pipes/sort.pipe';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { RespondentDetailsComponent } from './respondent-details/respondent-details.component';

@NgModule({
  imports: [
    ProjectsRoutingModule,
    SharedModule,
    NgbAccordionModule,
    NgbPopoverModule,
    StoreModule.forFeature('project', projectReducer),
    StoreModule.forFeature('massMail', massmailReducer),
    StoreModule.forFeature('template-goal', templateGoalReducer),
    StoreModule.forFeature('orgUnit', orgUnitReducer),
    StoreModule.forFeature('organization', organizationReducer),
    NxNaturalLanguageFormModule,
    NxInputModule,
    NxPopoverModule,
    EffectsModule.forFeature([
      MassmailEffects,
      MassmailFormEffects,
      ProjectEffects,
      TemplateGoalEffects,
      OrgUnitEffects,
      OrganizationEffects,
    ]),
    AngularEditorModule,
    QuillModule.forRoot(),
  ],
  declarations: [
    CreateProjectComponent,
    SelectProjectTypeComponent,
    EditProjectGoalComponent,
    EditGoalAccordionComponent,
    TemplateQuestionComponent,
    ProjectFormComponent,
    EditProjectComponent,
    BulkDeleteProjectsComponent,
    MassmailComponent,
    MassmaildetailsComponent,
    MassmailFormComponent,
    MassmailDateToSendFormComponent,
    DateComponent,
    TimeComponent,
    CopymailFormComponent,
    AddressFormComponent,
    AddressListComponent,
    SafeHtmlPipe,
    SortByProjectNamePipe,
    SortPipe,
    GeneralInfoComponent,
    RespondentDetailsComponent,
  ],
  providers: [
  ],
  exports: [],
})

export class ProjectsModule { }
