import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IOrganization } from '../../../shared/models/organization';
import { ORGANIZATION_ACTIONS } from './organization.action';

export interface IOrganizationState {
  organizations: IOrganization[],
}

export const initialState: IOrganizationState = {organizations: []};

export const organizationReducer = createReducer(
  initialState,

  on(ORGANIZATION_ACTIONS.storeOrganizations, (state, { payload }) => {
    return {
      ...state,
      organizations: payload,
    };
  }),

);


const getState = createFeatureSelector<IOrganizationState>('organization');

const getOrganizationFunc = (state: IOrganizationState) => state.organizations;

const getOrganizations = createSelector(
  getState,
  getOrganizationFunc,
);

export const ORGANIZATION_SELECTORS = {getOrganizations};
