<div class="d-flex flex-column mt-3" *ngIf="(editProject$ | async)">
  <div class="container card">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <h2 class="card-title mt-3 mb-3">Edit Project Settings</h2>
        <a [href]="oldBuilderUrl" class="btn btn-link mt-2 ml-2">Legacy Builder Edit</a>
        <a [href]="oldSettingsUrl" class="btn btn-link mt-2 ml-2">Legacy Settings View</a>
      </div>
      <survey-project-form
        [editProject]="(editProject$ | async)"
        (saveProject)="submitProject($event)">
      </survey-project-form>
    </div>
  </div>
</div>