import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ORGANIZATION_ACTIONS } from '../../projects/store/organization/organization.action';
import { PROJECT_ACTIONS } from '../../projects/store/project/project.actions';
import { ACCOUNT_ACTIONS } from '../account/account.actions';
import { MESSAGE_ACTIONS } from '../message/message.actions';
import { TEMPLATE_GOAL_ACTIONS } from '../template-goal/template-goal.actions';
import { LOADER_ACTIONS } from './loader.action';
import { MASSMAIL_ACTIONS } from '../../projects/store/massmail/massmail.actions';
import { MASSMAILFORM_ACTIONS } from '../../projects/store/massmailform/massmailform.actions';

@Injectable()
export class LoaderEffects {

  constructor(
    private actions$: Actions,
  ) { }

  // Unless you filter to a specific action.
  // NEVER: Have the same action in more than ONE effect.

  storeStartLoadingCurrentAccount$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        ACCOUNT_ACTIONS.getCurrentAccount,
        ACCOUNT_ACTIONS.storeAccount,
      ),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: ACCOUNT_ACTIONS.getCurrentAccount.type });
      }),
    ),
  );

  storeStartLoadingGetProject$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(PROJECT_ACTIONS.getProjectById),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: PROJECT_ACTIONS.getProjectById.type });
      }),
    ),
  );

  storeStopLoadingGetProject$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(PROJECT_ACTIONS.storeProject),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: PROJECT_ACTIONS.getProjectById.type });
      }),
    ),
  );

  storeStartLoadingGetOrganizations$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(ORGANIZATION_ACTIONS.getOrganizations),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: ORGANIZATION_ACTIONS.getOrganizations.type });
      }),
    ),
  );
  storeStopLoadingGetOrganizations$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(ORGANIZATION_ACTIONS.storeOrganizations),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: ORGANIZATION_ACTIONS.getOrganizations.type });
      }),
    ),
  );

  storeStartLoadingCreateProject$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        PROJECT_ACTIONS.createProject,
      ),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: PROJECT_ACTIONS.createProject.type });
      }),
    ),
  );
  storeStopLoadingCreateProject$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        MESSAGE_ACTIONS.success,
      ),
      filter(({ type, message }) => {
        // Make sure the only success message action that triggers this effect is for Project Creation.
        return !(type == MESSAGE_ACTIONS.success.type && message !== 'Project Created Successfully');
      }),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: PROJECT_ACTIONS.createProject.type });
      }),
    ),
  );

  // This Effect only has one action to start the load.
  // Because after we save the goals we then navigate to Community-Web. This way we have a loader for that transition
  // This also destroys the Angular App.
  storeStartLoadingSaveProjectGoals$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        PROJECT_ACTIONS.postProjectGoals,
      ),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: PROJECT_ACTIONS.postProjectGoals.type });
      }),
    ),
  );

  storeStartProjectsDeletion$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        PROJECT_ACTIONS.deleteBulkProjects,
      ),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: PROJECT_ACTIONS.deleteBulkProjects.type });
      }),
    ),
  );
  storeStopProjectsDeletion$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        MESSAGE_ACTIONS.success,
      ),
      filter(({ type, message }) => {
        // Make sure the only success message action that triggers this effect is for Project Deletion.
        return !(type == MESSAGE_ACTIONS.success.type && message !== 'Project deletion queued. You will receive an email once complete. You may leave this page.');
      }),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: PROJECT_ACTIONS.deleteBulkProjects.type });
      }),
    ),
  );

  storeStartLoadingProjectWithGoals$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        PROJECT_ACTIONS.getProjectWithGoals,
        PROJECT_ACTIONS.storeProjectWithGoals,
      ),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: PROJECT_ACTIONS.getProjectWithGoals.type });
      }),
    ),
  );

  storeStartLoadingTemplateGoals$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        TEMPLATE_GOAL_ACTIONS.getTemplateGoals,
        TEMPLATE_GOAL_ACTIONS.storeTemplateGoals,
      ),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: TEMPLATE_GOAL_ACTIONS.getTemplateGoals.type });
      }),
    ),
  );

  storeStartLoadingMassmail$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        MASSMAIL_ACTIONS.getMassMailByProjectId,
        MASSMAIL_ACTIONS.storeMassMail,
        MASSMAIL_ACTIONS.getMassMailByEmailId,
        MASSMAIL_ACTIONS.getMassMailReminderByEmailId,
        MASSMAIL_ACTIONS.storeMassMailByEmailId,
      ),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: MASSMAIL_ACTIONS.getMassMailByProjectId.type });
      }),
    ),
  );

  storeStartLoadingMassMailProject$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        MASSMAIL_ACTIONS.getProjectByProjectId,
        MASSMAIL_ACTIONS.storeProject,
      ),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: MASSMAIL_ACTIONS.getProjectByProjectId.type });
      }),
    ),
  );

  storeStartLoadingMassMailAllProjects$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        MASSMAIL_ACTIONS.getAllProjects,
        MASSMAIL_ACTIONS.storeAllProjects,
      ),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: MASSMAIL_ACTIONS.getAllProjects.type });
      }),
    ),
  );

  storeStartLoadingProjectEmailResult$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        MASSMAILFORM_ACTIONS.createProjectEmail,
        MASSMAILFORM_ACTIONS.updateProjectEmail,
        MASSMAILFORM_ACTIONS.scheduleProjectEmail,
        MASSMAILFORM_ACTIONS.unscheduleProjectEmail,
      ),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: MASSMAILFORM_ACTIONS.createProjectEmail.type });
      }),
    ),
  );

  storeStopLoadingProjectEmailResult$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        MASSMAILFORM_ACTIONS.storeProjectEmail,
      ),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName: MASSMAILFORM_ACTIONS.createProjectEmail.type });
      }),
    ),
  );

  storeStartLoadingPreviewMailingFormat$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        MASSMAIL_ACTIONS.getPreviewMailingFormat,
        MASSMAIL_ACTIONS.storePreviewMailingFormat,
      ),
      map(() => {
        return LOADER_ACTIONS.storeIsLoading({ keyName:MASSMAIL_ACTIONS.getPreviewMailingFormat.type });
      }),
    ),
  );

  // If the danger message action doesn't have a loaderkey than don't pass to loading reducer
  storeErrorStopLoading$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(
        MESSAGE_ACTIONS.danger,
      ),
      map(() => {
        return LOADER_ACTIONS.hideAllLoadIndicators();
      }),
    ),
  );

  // AJB: As discussed, these two effects are the only ones we will need to show/hide loaders
  //
  //showLoadIndicatorEvent$ = createEffect((): Observable<Action> =>
  //  this.actions$.pipe(
  //    ofType(LOADER_ACTIONS.showLoadIndicator),
  //    map(({ keyName }) => {
  //      return LOADER_ACTIONS.showLoadIndicator({ keyName: keyName });
  //    }),
  //  ),
  //);
  //
  //hideLoadIndicatorEvent$ = createEffect((): Observable<Action> =>
  //  this.actions$.pipe(
  //    ofType(LOADER_ACTIONS.hideLoadIndicator),
  //    map(({ keyName }) => {
  //      return LOADER_ACTIONS.hideLoadIndicator({ keyName: keyName });
  //    }),
  //  ),
  //);
}
