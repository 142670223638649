import { Component, OnInit, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { MASSMAIL_ACTIONS } from '../store/massmail/massmail.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, take } from 'rxjs/operators';
import { MASSMAIL_SELECTORS } from '../store/massmail/massmail.reducer';
import { IMassMailStatus, IMassMailResult } from '../../shared/models/mass-mail-status';
import { lastValueFrom, Observable } from 'rxjs';
import { IProjectEmailResult } from '../../shared/models/project-email-result';
import { environment } from 'projects/surveys/src/environments/environment';
import * as moment from 'moment-timezone';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { MESSAGE_ACTIONS } from '../../store/message/message.actions';

@Component({
  selector: 'app-massmail',
  templateUrl: './massmail.component.html',
  styleUrls: ['./massmail.component.scss'],
})
export class MassmailComponent implements OnInit {
  displayErrorMsg = false;
  errorMsg = '';
  highlighrow = '';
  massMailStatus$: Observable<IMassMailStatus>;
  dateFormat = 'M/d/yy h:mm a';
  projectId: number;
  projectEmailId: number;
  undeliveredFile$: Observable<ArrayBuffer>;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private http: HttpClient,
  ) {
    this.route.paramMap.pipe(take(1)).subscribe(paramMap => {
      this.projectId = +paramMap.get('projectId');
      this.store.dispatch(MASSMAIL_ACTIONS.getMassMailByProjectId({ projectId: this.projectId }));
    });
  }

  ngOnInit(): void {
    this.massMailStatus$ = this.store.select(MASSMAIL_SELECTORS.getMassMail);
  }

  goBack(): void {
    window.location.assign(this.getProjectDashboardUrl(this.projectId));
  }

  openModal(modal: TemplateRef<NgbModal>, emailResult: IProjectEmailResult): void {
    if (emailResult.status.toLowerCase() != 'sent') {
      this.projectEmailId = emailResult.projectEmailId;
      this.modalService.open(modal, { centered: true });
    }
  }

  getScheduledDate(emailResult: IMassMailResult): string {
    if (!emailResult || !emailResult.scheduledSendDateEastern || emailResult.scheduledSendDateEastern == null || emailResult.status.toLowerCase() == 'draft') {
      return '';
    }

    const scheduledDate = moment.tz(emailResult.scheduledSendDateEastern, 'America/New_York');
    let dateString = scheduledDate.format('MM/DD/YYYY hh:mm A (z)');

    if (scheduledDate.isBefore(moment()) && emailResult.status.toLowerCase() == 'scheduled') {
      dateString = 'Pending';
    }

    return dateString;
  }

  deleteMassMail(): void {
    this.modalService.dismissAll();
    this.displayErrorMsg = false;

    //Delete api implementaion
    this.store.dispatch(MASSMAIL_ACTIONS.deleteMassmailByProjectId({ projectId: this.projectId, emailId: this.projectEmailId }));
  }

  changeStyle($event, emailResult): void {
    emailResult.highlightrow = $event.type == 'mouseover' ? 'highlightrow' : '';
  }

  redirectToCopyDetailsPage() {
    this.router.navigateByUrl(this.getMassmailUrl(this.projectId) + '/copy');
  }

  redirectToCreateDetailsPage() {
    this.router.navigateByUrl(this.getMassmailUrl(this.projectId) + '/create');
  }

  redirectToEditDetailsPage(emailResult: IProjectEmailResult) {
    this.router.navigateByUrl(this.getMassmailUrl(this.projectId) + `/${emailResult.projectEmailId}/edit`);
  }

  redirectToCreateReminderPage(emailResult: IProjectEmailResult) {
    this.router.navigateByUrl(this.getMassmailUrl(this.projectId) + `/${emailResult.projectEmailId}/reminder`);
  }

  downloadUndeliveredFile(emailResult: IProjectEmailResult) {
    const url = `https://localhost/fe-api/surveys/project/${this.projectId}/massmail/${emailResult.projectEmailId}/undeliverablemail`;

    lastValueFrom(this.http.get(url, { responseType: 'arraybuffer' })
      .pipe(map((file: ArrayBuffer) => {
        return file;
      })))
      .then(res => {
        const filename = 'UndeliverableMailMessages.csv';
        const blob = new Blob([res], { type: 'text/csv' }); saveAs(blob, filename);
      });
    // TODO: Fix the code below so we use the store and actions to download the file.
    // The code above works, but the action below downloads a file that has [object Object] instead of the actual data.
    //this.store.dispatch(MASSMAIL_ACTIONS.downloadUndeliveredFile({ projectId: this.projectId, emailId: emailResult.projectEmailId }));
    //this.undeliveredFile$ = this.store.select(MASSMAIL_SELECTORS.getUndeliveredFile);
    //this.undeliveredFile$.subscribe((results: ArrayBuffer) => {
    //  const blob = new Blob([results], { type: 'text/csv' });
    //  saveAs(blob, 'UndeliverableMailMessages.csv');
    //});
  }

  getProjectDashboardUrl(projectId: number): string {
    let origin = window.location.origin.replace('/+$', '');
    if (origin.includes('localhost:4200')) {
      origin = 'https://localhost';
    }
    return `${origin}/app/ClientWeb/Project.aspx?ProjectId=${projectId}`;
  }

  getMassmailUrl(projectId: number): string {
    return `/project/${projectId}/massmail`;
  }
}
