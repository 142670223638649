import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/surveys/src/environments/environment';
import { Observable } from 'rxjs';
import { ICurrentAccountResponse } from '../../models/account';
import { BaseHttpService } from './base-http.service';

@Injectable({ providedIn: 'root' })
export class UserSettingsHttpService extends BaseHttpService {
  constructor(h: HttpClient) {
    super(h);
  }


  private _buildUrl(append = ''): string {
    return `${environment.BASELINE_API}/organizations/accounts/${append}`;
  }

  getUserAccount(): Observable<ICurrentAccountResponse> {
    return this.getRequest({
      url: this._buildUrl('current'),
      query: null,
    });
  }

  signOut(): void {
    window.location.assign(environment.SIGN_OUT_URL);
  }
}
