import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IMessage } from '../../shared/models/message';

const danger = createAction('MESSAGE-danger', props<{ errorResponse: HttpErrorResponse, loaderKey?: string }>());
const success = createAction('MESSAGE-success', props<{ message: string }>());
const info = createAction('MESSAGE-info', props<{ message: string }>());
const warning = createAction('MESSAGE-warning', props<{ message: string }>());
const showMessage = createAction('MESSAGE-showMessage', props<{ payload: IMessage }>());
const hideMessage = createAction('MESSAGE-hideMessage', props<{ messageId: number }>());
const none = createAction('MESSAGE-none');

export const MESSAGE_ACTIONS = {
  danger,
  success,
  info,
  warning,
  showMessage,
  hideMessage,
  none,
};
