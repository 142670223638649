import { createAction, props } from '@ngrx/store';

const storeIsLoading = createAction('LOADER-storeIsLoading', props<{ keyName: string; }>());

//const showLoadIndicator = createAction('LOADER-showLoadIndicator', props<{ keyName: string; }>());
//const hideLoadIndicator = createAction('LOADER-hideLoadIndicator', props<{ keyName: string; }>());
const hideAllLoadIndicators = createAction('LOADER-hideAllLoadIndicators');

export const LOADER_ACTIONS = {
  storeIsLoading,
  //showLoadIndicator,
  //hideLoadIndicator,
  hideAllLoadIndicators,
};
