import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [DatePipe],
})
export class DateComponent implements OnInit {
  @Output() onDateChange = new EventEmitter<any>();
  @Input() selectedDate: any;
  @Input() isDisabled: boolean;
  disabled = false;

  dateForm = new FormGroup(
    { selectedDate: new FormControl('') },
    { validators: [] },
  );
  constructor(private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.disabled = this.isDisabled;
  }

  ngOnChanges(changes: any) {
    if (changes['selectedDate']) {
      if (this.selectedDate) {
        this.dateForm.controls['selectedDate'].setValue(moment(this.selectedDate).format('YYYY-MM-DD'));
      } else {
        this.dateForm.controls['selectedDate'].setValue(null);
      }
    }

    if (changes['isDisabled']) {
      this.disabled = this.isDisabled;
    }
  }
  emitDateChange(): void {
    /* Datepicker has weird issue when manually entering date.
      I.E when user enters first digit of an year plugin gives us a callback with 0002,
      Which is not a valid year, so to make sure user has entered all 4 digits of an year below logic added
    */
    const selectedDate: string = this.dateForm.controls['selectedDate'].value;
    if (selectedDate && selectedDate.indexOf('-') >= 0) {
      const year = +selectedDate.split('-')[0];
      if (year && year >= 1000) {
        this.onDateChange.emit(this.dateForm.controls['selectedDate'].value);
      }
    }
  }

  submitDateForm(): void {
  }

}
