import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LOADER_SELECTORS } from '../../../store/loader/loader.reducer';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  isLoading$: Observable<boolean>;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.isLoading$ = this.store.select(LOADER_SELECTORS.getIsLoading);
  }

}
