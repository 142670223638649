import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ICurrentAccountResponse } from '../../shared/models/account';
import { UserSettingsService } from '../../shared/services/user-settings.service';
import { MESSAGE_ACTIONS } from '../message/message.actions';
import { ACCOUNT_ACTIONS } from './account.actions';

@Injectable()
export class AccountEffects {

  constructor(
    private actions$: Actions,
    private userSettingsService: UserSettingsService,
  ) {}


  setCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.getCurrentAccount),
      mergeMap(() =>
        this.userSettingsService.getUserAccount().pipe(
          map((userAccount: ICurrentAccountResponse) => {

            if (window['Appcues']) {

              //prefix all the property names with 'baseline'
              const appCuesObject = {
                baselineExternalId: userAccount?.account?.externalId,
                baselineFirstName: userAccount?.account?.firstName,
                baselineLastName: userAccount?.account?.lastName,
                baselineInstitutionName: userAccount?.institution?.name,
              };

              window['Appcues'].identify(userAccount.account.userId, appCuesObject);
            }

            return ACCOUNT_ACTIONS.storeAccount({ payload: userAccount });
          }),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse, loaderKey: ACCOUNT_ACTIONS.getCurrentAccount.type })),
          ),
        ),
      ),
    ),
  );

}