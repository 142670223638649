import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/surveys/src/environments/environment';
import { Observable } from 'rxjs';
import { BaseHttpService } from './base-http.service';
import { IMassMailStatus } from '../../models/mass-mail-status';
import { IProjectEmailResult } from '../../models/project-email-result';

@Injectable({ providedIn: 'root' })
export class MassmailHttpService extends BaseHttpService {
  constructor(h: HttpClient) {
    super(h);
  }

  private _buildUrl(append = ''): string {
    return `${environment.BASELINE_API}/surveys/${append}`;
  }

  getMassMail(projectId: number): Observable<IMassMailStatus> {
    return this.getRequest({
      url: this._buildUrl(`projects/${projectId}/massmail`),
      query: {},
    });
  }

  deleteMassMail(projectId: number, projectEmailId: number): Observable<boolean> {
    return this.deleteRequest({ url: this._buildUrl(`projects/${projectId}/massmail/${projectEmailId}`) });
  }

  getMassMailByEmailId(projectId: number, projectEmailId: number): Observable<boolean> {
    return this.getRequest({ url: this._buildUrl(`projects/${projectId}/massmail/${projectEmailId}`) });
  }

  getMassMailReminderByEmailId(projectId: number, projectEmailId: number): Observable<boolean> {
    return this.getRequest({ url: this._buildUrl(`projects/${projectId}/massmail/${projectEmailId}/reminder`) });
  }

  getProjectByProjectId(projectId: number): Observable<boolean> {
    return this.getRequest({ url: this._buildUrl(`projects/${projectId}`) });
  }

  getPanelsByProjectId(projectId: number): Observable<boolean> {
    return this.getRequest({ url: this._buildUrl(`projects/${projectId}/panels`) });
  }

  getRecipientListByPanelId(projectId: number, panelId: number): Observable<boolean> {
    return this.getRequest({ url: this._buildUrl(`projects/${projectId}/panel/${panelId}/recipients`) });
  }

  getAllProjects(): Observable<boolean> {
    return this.getRequest({ url: this._buildUrl('projects/projectlist') });
  }

  createProjectEmail(projectId: number, massMailData: any): Observable<any> {
    return this.postRequest({
      url: this._buildUrl(`projects/${projectId}/massmail`),
      body: massMailData,
      query: null,
    });
  }

  updateProjectEmail(projectId: number, emailId: number, massMailData: any): Observable<any> {
    return this.patchRequest({
      url: this._buildUrl(`projects/${projectId}/massmail/${emailId}`),
      body: massMailData,
      query: null,
    });
  }

  scheduleProjectEmail(projectId: number, emailId: number, massMailData: any): Observable<any> {
    return this.patchRequest({
      url: this._buildUrl(`projects/${projectId}/massmail/${emailId}/schedule`),
      body: massMailData,
      query: null,
    });
  }

  unscheduleProjectEmail(projectId: number, emailId: number, massMailData: any): Observable<any> {
    return this.patchRequest({
      url: this._buildUrl(`projects/${projectId}/massmail/${emailId}/unschedule`),
      body: massMailData,
      query: null,
    });
  }

  getPreviewMailingFormat(projectId: number, emailId: number, massMailData: any): Observable<boolean> {
    return this.postRequest({
      url: this._buildUrl(`projects/${projectId}/massmail/${emailId}/format`),
      body: massMailData,
      query: null,
    });
  }

  downloadUndeliveredFile(projectId: number, emailId: number): Observable<ArrayBuffer> {
    return this.getRequest<ArrayBuffer>({ url: this._buildUrl(`projects/${projectId}/massmail/${emailId}/undeliverablemail`) });
  }
}
