import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ProjectType } from '../../../shared/enums/project-type';
import { IProject, IProjectGoalRequest, IProjectTemplateGoalAttrs } from '../../../shared/models/project';
import { ITemplateDimension, ITemplateGoal, ITemplateQuestion, ITemplateQuestionSeparation } from '../../../shared/models/template-goal';
import { PROJECT_ACTIONS } from './project.actions';


export interface IProjectState {
  project: IProject;
  isLinkValid?: boolean;
  isCheckingGenericLink?: boolean;
  projectTemplateGoalAttrs: IProjectTemplateGoalAttrs;
  hasCustomInputError?: boolean;
  projectType?: ProjectType;
}

export const initialState: IProjectState = {
  project: null,
  isLinkValid: null,
  isCheckingGenericLink: null,
  projectTemplateGoalAttrs: {
    instructions: null,
    templateGoals: null,
  },
  hasCustomInputError: null,
  projectType: null,
};

export const projectReducer = createReducer(
  initialState,

  on(PROJECT_ACTIONS.storeProjectType, (state, { projectType } ) => {
    return {
      ...state,
      projectType: projectType,
    };
  }),

  on(PROJECT_ACTIONS.checkIsLinkValid, (state) => {
    return {
      ...state,
      isCheckingGenericLink: true,
    };
  }),

  on(PROJECT_ACTIONS.storeIsLinkValid, (state, { isValid }) => {
    return {
      ...state,
      isLinkValid: isValid,
      isCheckingGenericLink: false,
    };
  }),

  on(PROJECT_ACTIONS.storeHasCustomQuestionTextError, (state, { hasError }) => {
    return {
      ...state,
      hasCustomInputError: hasError,
    };
  }),

  on(PROJECT_ACTIONS.storeProject, (state, { project }) => {
    // This is used for when we get the project by Id to edit the settings.

    const projectToEdit: IProject = { ...project };

    return {
      ...state,
      project: projectToEdit,
      projectType: projectToEdit.type,
    };
  }),

  on(PROJECT_ACTIONS.storeProjectWithGoals, (state, { payload }) => {
    // The only times this will be called is to get a projects goals when editing.
    // AND on the final save when editing so always rewrite set the project and the template goal attrs.

    const project: IProject = { ...payload };
    const copiedGoals = [].concat(...payload.goals.templateGoals);
    // Sort Goals by order
    const orderedGoals: ITemplateGoal[] = copiedGoals.sort((a, b) => {
      return a.order - b.order;
    });

    const goalMap = new Map<number, Map<number, ITemplateDimension>>();
    // Convert array of goals and dimensions to easily usable Map
    orderedGoals.forEach(goal => {
      const dimMap = new Map();
      goal.templateDimensions.forEach(dim => {

        dimMap.set(dim.templateDimensionId, {
          ...dim,
          dimensionPanelId: `panel-${dim.templateDimensionId}`,
        });
      });
      goalMap.set(goal.templateGoalId, dimMap);
    });

    const projectTemplateGoalAttrs: IProjectTemplateGoalAttrs = {
      templateGoals: goalMap,
      instructions: payload.goals.instructions,
    };


    return {
      ...state,
      project,
      projectTemplateGoalAttrs,
    };
  }),

  // This is fired when a single dimension is clicked
  on(PROJECT_ACTIONS.storeToggleDimension, (state, { templateDimension, templateGoalId }) => {
    const newGoalsMap = new Map(state.projectTemplateGoalAttrs.templateGoals);
    const goalDimMap = newGoalsMap.get(templateGoalId);

    if (goalDimMap.has(templateDimension.templateDimensionId)) {
      goalDimMap.delete(templateDimension.templateDimensionId);
    } else {
      goalDimMap.set(templateDimension.templateDimensionId, templateDimension);
    }

    return {
      ...state,
      projectTemplateGoalAttrs: {
        instructions: state.projectTemplateGoalAttrs.instructions,
        templateGoals: newGoalsMap,
      },
    };
  }),

  // This is fired when a select all dimensions is clicked for a goal
  on(PROJECT_ACTIONS.storeToggleGoalRemove, (state, { templateGoalId }) => {
    const newGoalsMap = new Map(state.projectTemplateGoalAttrs.templateGoals);
    const goalToToggleAll = newGoalsMap.get(templateGoalId);

    goalToToggleAll.clear();

    return {
      ...state,
      projectTemplateGoalAttrs: {
        instructions: state.projectTemplateGoalAttrs.instructions,
        templateGoals: newGoalsMap,
      },
    };
  }),

  on(PROJECT_ACTIONS.storeToggleGoalAdd, (state, { templateDimensions, templateGoalId }) => {
    const newGoalsMap = new Map(state.projectTemplateGoalAttrs.templateGoals);
    const goalToToggleAll = newGoalsMap.get(templateGoalId);

    templateDimensions.forEach(dim => {
      goalToToggleAll.set(dim.templateDimensionId, dim);
    });

    return {
      ...state,
      projectTemplateGoalAttrs: {
        instructions: state.projectTemplateGoalAttrs.instructions,
        templateGoals: newGoalsMap,
      },
    };
  }),

  on(PROJECT_ACTIONS.storeGoalOnProject, (state, { payload }) => {
    const goalMap = new Map<number, Map<number, ITemplateDimension>>();

    // Convert array of goal dimensions to easily usable Map
    const dimMap = new Map();
    payload.templateDimensions.forEach(dim => {
      dimMap.set(dim.templateDimensionId, {...dim});
    });
    goalMap.set(payload.templateGoalId, dimMap);
    return {
      ...state,
      projectTemplateGoalAttrs: {
        instructions: state.projectTemplateGoalAttrs.instructions,
        templateGoals: goalMap,
      },
    };
  }),

  on(PROJECT_ACTIONS.storeInstructions, (state, { payload }) => {
    return {
      ...state,
      projectTemplateGoalAttrs: {
        instructions: payload,
        templateGoals: state.projectTemplateGoalAttrs.templateGoals,
      },
    };
  }),

  on(PROJECT_ACTIONS.storeQuestionText, (state, { templateLookUp, newQuestionText }) => {
    const newGoalsMap = new Map(state.projectTemplateGoalAttrs.templateGoals);
    const currGoal = newGoalsMap.get(templateLookUp.templateGoalId);
    let currDim = currGoal.get(templateLookUp.templateDimensionId);
    // New Map doesn't clone arrays so clone them here.
    const cloneArr = [...currDim.templateQuestions];
    let questionIndex;
    const currQuestion = cloneArr.find((question, index) => {
      if (question.templateQuestionId == templateLookUp.templateQuestionId) {
        questionIndex = index;
        return true;
      }
      return false;
    });
    // Update questionText for the question.
    const newQuestion: ITemplateQuestion = {
      ...currQuestion,
      questionTextOverride: newQuestionText,
    };

    // Replace the old question with the new one in the Dimension Map
    cloneArr[questionIndex] = newQuestion;
    currDim = {
      ...currDim,
      templateQuestions: cloneArr,
    };
    currGoal.set(currDim.templateDimensionId, currDim);

    return {
      ...state,
      projectTemplateGoalAttrs: {
        instructions: state.projectTemplateGoalAttrs.instructions,
        templateGoals: newGoalsMap,
      },
    };
  }),
);


const getState = createFeatureSelector<IProjectState>('project');

const getProjectFunc = (state: IProjectState) => state.project;
const getIsLinkValidFunc = (state: IProjectState) => state.isLinkValid;
const getProjectGoalsFunc = (state: IProjectState) => state.projectTemplateGoalAttrs.templateGoals;
const getProjectGoalIdsFunc = (state: IProjectState) => Array.from(state.projectTemplateGoalAttrs.templateGoals.keys());
const getProjectGoalsAttrsFunc = (state: IProjectState) => state.projectTemplateGoalAttrs;
const getIsCheckingGenericLinkFunc = (state: IProjectState) => state.isCheckingGenericLink;
const getInstructionsFunc = (state: IProjectState) => state.projectTemplateGoalAttrs.instructions;
const getProjectGoalRequestFunc = (state: IProjectState, goalIds: number[]) => {
  const goalRequests: IProjectGoalRequest[] = [];

  goalIds.forEach(goalId => {
    // Create an array of the selected dimension requests.
    const dims = Array.from(state.projectTemplateGoalAttrs.templateGoals.get(goalId).values());
    const selectedDimensions = dims.map(dim => {
      // As of right now there will only be one question per dimension.
      const question = dim.templateQuestions[0];
      // If there is no questionTextOverride use questionTextTemplate instead.
      // This is only true on initial creation or if the user toggles on/off dimensions or goals.
      const displayText = !question.questionTextOverride ? question.questionTextTemplate : question.questionTextOverride;
      return {
        templateDimensionId: dim.templateDimensionId,
        questionText: displayText,
      };
    });

    // Populate the Goal Request Array
    goalRequests.push({
      templateGoalId: goalId,
      selectedDimensions,
    });
  });
  return goalRequests;
};

const separateQuestionText = (questionText): ITemplateQuestionSeparation[] => {
  const initArr = questionText.split(new RegExp('(?=\\{)|(?<=\\})'));
  return initArr.reduce((finalArr, text) => {
    if (text.includes('{')) {
      text = text.replace('{', '');
      text = text.replace('}', '');
      finalArr.push({
        text,
        isInput: true,
      });
    } else {
      finalArr.push({
        text,
        isInput: false,
      });
    }
    return finalArr;
  }, []);
};

const getQuestionTextSeparationFunc = (state: IProjectState, templateGoals: Map<number, Map<number, ITemplateDimension>>) => {
  const questionMap = new Map();
  Array.from(templateGoals.values()).forEach((dimMap) => {
    Array.from(dimMap.values()).forEach(dim => {
      dim.templateQuestions.forEach(question => {
        // If there is no questionTextOverride use questionTextTemplate instead.
        // This is only true on initial creation or if the user toggles on/off dimensions or goals.
        const displayText = !question.questionTextOverride ? question.questionTextTemplate : question.questionTextOverride;
        const questionSeparationArr = separateQuestionText(displayText);
        questionMap.set(question.templateQuestionId, questionSeparationArr);
      });
    });
  });
  return questionMap;
};

const getHasCustomInputErrorFunc = (state: IProjectState) => state.hasCustomInputError;
const getProjectTypeFunc = (state: IProjectState) => state.projectType;

const getProject = createSelector(
  getState,
  getProjectFunc,
);
const getIsLinkValid = createSelector(
  getState,
  getIsLinkValidFunc,
);
const getProjectGoals = createSelector(
  getState,
  getProjectGoalsFunc,
);
const getProjectGoalIds = createSelector(
  getState,
  getProjectGoalIdsFunc,
);
const getProjectGoalAttrs = createSelector(
  getState,
  getProjectGoalsAttrsFunc,
);
const getIsCheckingGenericLink = createSelector(
  getState,
  getIsCheckingGenericLinkFunc,
);
const getProjectGoalRequest = createSelector(
  getState,
  getProjectGoalIds,
  getProjectGoalRequestFunc,
);
const getInstructions = createSelector(
  getState,
  getInstructionsFunc,
);

const getSeparatedQuestionText = createSelector(
  getState,
  getProjectGoals,
  getQuestionTextSeparationFunc,
);

const getHasCustomInputError = createSelector(
  getState,
  getHasCustomInputErrorFunc,
);

const getProjectType = createSelector(
  getState,
  getProjectTypeFunc,
);

export const PROJECT_SELECTORS = {
  getProject,
  getIsLinkValid,
  getProjectGoals,
  getProjectGoalIds,
  getProjectGoalAttrs,
  getIsCheckingGenericLink,
  getProjectGoalRequest,
  getInstructions,
  getSeparatedQuestionText,
  getHasCustomInputError,
  getProjectType,
};
