import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { LOADER_ACTIONS } from './loader.action';


export interface ILoaderState {
  isLoading: Map<string, boolean>;
}

export const initialState: ILoaderState = { isLoading: new Map() };

export const loaderReducer = createReducer(
  initialState,

  on(LOADER_ACTIONS.storeIsLoading, (state, { keyName }) => {
    const newLoadingMap = new Map(state.isLoading);
    const newIsLoading = !newLoadingMap.get(keyName);
    newLoadingMap.set(keyName, newIsLoading);

    return { isLoading: newLoadingMap };
  }),

  //on(LOADER_ACTIONS.showLoadIndicator, (state, { keyName }) => {
  //  const newLoadingMap = new Map(state.isLoading);
  //
  //  newLoadingMap.set(keyName, true);
  //  return { isLoading: newLoadingMap };
  //}),
  //
  //on(LOADER_ACTIONS.hideLoadIndicator, (state, { keyName }) => {
  //  const newLoadingMap = new Map(state.isLoading);
  //
  //  newLoadingMap.set(keyName, false);
  //  return { isLoading: newLoadingMap };
  //}),

  on(LOADER_ACTIONS.hideAllLoadIndicators, (() => {
    return { isLoading: new Map() };
  })),

);

const getState = createFeatureSelector<ILoaderState>('loader');

const getIsLoadingFunc = (state: ILoaderState) => {
  return Array.from(state.isLoading.values()).some(isLoadingVal => {
    return isLoadingVal;
  });
};

const getIsLoading = createSelector(
  getState,
  getIsLoadingFunc,
);

const getKeyListFunc = (state: ILoaderState) => {
  let retStr = '';

  state.isLoading.forEach((value, key) => {
    if (value) {
      retStr += ',' + key;
    }
  });
  return retStr.substring(1);
};

const getKeysStillLoading = createSelector(
  getState,
  getKeyListFunc,
);

export const LOADER_SELECTORS = {
  getIsLoading,
  getKeysStillLoading,
};
