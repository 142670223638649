import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { MassmailHttpService } from '../../../shared/services/communication/massmail-http.service';
import { MESSAGE_ACTIONS } from '../../../store/message/message.actions';
import { MASSMAILFORM_ACTIONS } from './massmailform.actions';
import { IProjectEmailResult } from '../../../shared/models/project-email-result';
import { LOADER_ACTIONS } from '../../../store/loader/loader.action';
import { environment } from '../../../../environments/environment';

@Injectable()
export class MassmailFormEffects {

  constructor(
    private actions$: Actions,
    private massmailHttpService: MassmailHttpService,
    private router: Router,
    private store: Store,
  ) { }

  createProjectEmail$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAILFORM_ACTIONS.createProjectEmail),
      mergeMap(({ projectId, massmailform }) =>
        this.massmailHttpService.createProjectEmail(projectId, massmailform).pipe(
          map((email: IProjectEmailResult) => {
            MASSMAILFORM_ACTIONS.storeProjectEmail({ email });
            this.store.dispatch(LOADER_ACTIONS.storeIsLoading({ keyName: MASSMAILFORM_ACTIONS.createProjectEmail.type }));
            this.router.navigateByUrl(this.getMassmailUrl(projectId));
            return MESSAGE_ACTIONS.success({ message: 'Mass Mail Created Successfully' });
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.store.dispatch(LOADER_ACTIONS.storeIsLoading({ keyName: MASSMAILFORM_ACTIONS.createProjectEmail.type }));
            return of(MESSAGE_ACTIONS.danger({ errorResponse }));
          }),
        ),
      ),
    ),
  );

  updateProjectEmail$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAILFORM_ACTIONS.updateProjectEmail),
      mergeMap(({ projectId, emailId, massmailform }) =>
        this.massmailHttpService.updateProjectEmail(projectId, emailId, massmailform).pipe(
          map((email: IProjectEmailResult) => {
            MASSMAILFORM_ACTIONS.storeProjectEmail({ email });
            this.store.dispatch(LOADER_ACTIONS.storeIsLoading({ keyName: MASSMAILFORM_ACTIONS.createProjectEmail.type }));
            this.router.navigateByUrl(this.getMassmailUrl(projectId));
            return MESSAGE_ACTIONS.success({ message: 'Mass Mail Updated Successfully' });
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.store.dispatch(LOADER_ACTIONS.storeIsLoading({ keyName: MASSMAILFORM_ACTIONS.createProjectEmail.type }));
            return of(MESSAGE_ACTIONS.danger({ errorResponse }));
          }),
        ),
      ),
    ),
  );

  scheduleProjectEmail$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAILFORM_ACTIONS.scheduleProjectEmail),
      mergeMap(({ projectId, emailId, massmailform }) =>
        this.massmailHttpService.scheduleProjectEmail(projectId, emailId, massmailform).pipe(
          map((email: IProjectEmailResult) => {
            MASSMAILFORM_ACTIONS.storeProjectEmail({ email });
            this.store.dispatch(LOADER_ACTIONS.storeIsLoading({ keyName: MASSMAILFORM_ACTIONS.createProjectEmail.type }));
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(()=> {
              this.router.navigateByUrl(this.getMassmailUrl(projectId) + `/${email.projectEmailId}/edit`);
            });
            return MESSAGE_ACTIONS.success({ message: 'Mass Mail has been scheduled to send' });
          }),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse, loaderKey: MASSMAILFORM_ACTIONS.scheduleProjectEmail.type })),
          ),
        ),
      ),
    ),
  );

  unscheduleProjectEmail$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(MASSMAILFORM_ACTIONS.unscheduleProjectEmail),
      mergeMap(({ projectId, emailId, massmailform }) =>
        this.massmailHttpService.unscheduleProjectEmail(projectId, emailId, massmailform).pipe(
          map((email: IProjectEmailResult) => {
            MASSMAILFORM_ACTIONS.storeProjectEmail({ email });
            this.store.dispatch(LOADER_ACTIONS.storeIsLoading({ keyName: MASSMAILFORM_ACTIONS.createProjectEmail.type }));
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(()=> {
              this.router.navigateByUrl(this.getMassmailUrl(projectId) + `/${email.projectEmailId}/edit`);
            });
            return MESSAGE_ACTIONS.success({ message: 'Mass Mail is now in draft mode' });
          }),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse, loaderKey: MASSMAILFORM_ACTIONS.unscheduleProjectEmail.type })),
          ),
        ),
      ),
    ),
  );

  getMassmailUrl(projectId: number): string {
    return `/project/${projectId}/massmail`;
  }
}
