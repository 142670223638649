export const environment = {
  production: false,
  HOME_PAGE_PATH: '',
  BASE_PATH: '/baseline/collect',
  PROFILE_PATH: '/app/Profile.aspx',
  BASELINE_API: '/baseline/fe-api',
  AUTH_URL: '/app/signin.aspx',
  HELP_URL: 'https://baselinesupport.campuslabs.com/hc/en-us',
  SIGN_OUT_URL: '/app/signout.aspx',
  APP_INSIGHTS_INSTRUMENTATION_KEY: '26a73189-b561-46e8-8155-5cfa634bee19',
  OLD_SETTINGS_URL: '/app/ClientWeb/Builder/ProjectProperty.aspx',
  OLD_BUILDER_URL: '/app/ClientWeb/Builder/Builder.aspx',
};
