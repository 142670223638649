import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ProjectType } from '../../../shared/enums/project-type';
import { IProject, IProjectGoalRequest, IProjectRequest, IProjectWithGoalsResponse } from '../../../shared/models/project';
import { ITemplateDimension, ITemplateGoal, ITemplateGoalLookUp, ITemplateQuestionSeparation } from '../../../shared/models/template-goal';

const checkIsLinkValid = createAction('PROJECT-checkIsLinkValid', props<{link: string, projectId?: number }>());
const storeIsLinkValid = createAction('PROJECT-storeIsLinkValid', props<{isValid: boolean}>());
const createProject = createAction('PROJECT-createProject', props<{ payload: IProjectRequest }>());
const getProjectWithGoals = createAction('PROJECT-getProjectGoals', props<{ payload: string }>());
const storeProjectWithGoals = createAction('PROJECT-storeProjectGoals', props<{ payload: IProjectWithGoalsResponse }>());
const storeToggleDimension = createAction('PROJECT-storeToggleDimension', props<{ templateDimension: ITemplateDimension, templateGoalId: number }>());
const storeToggleGoalRemove = createAction('PROJECT-storeToggleGoalRemove', props<{ templateGoalId: number }>());
const storeToggleGoalAdd = createAction('PROJECT-storeToggleGoalAdd', props<{ templateDimensions: ITemplateDimension[], templateGoalId: number }>());
const storeGoalOnProject = createAction('PROJECT-storeGoalOnProject', props<{ payload: ITemplateGoal }>());
const storeInstructions = createAction('PROJECT-storeInstructions', props<{ payload: string }>());
const postProjectGoals = createAction('PROJECT-postProjectGoals', props<{ projectId: number, projectGoalRequests: IProjectGoalRequest[], instructions: string }>());
const errorSavingProject = createAction('PROJECT-errorSavingProject', props<{ errorResponse: HttpErrorResponse }>());
const updateQuestionText = createAction('PROJECT-updateQuestionText', props<{ templateLookUp: ITemplateGoalLookUp, questionTextSeparation: ITemplateQuestionSeparation[] }>());
const storeQuestionText = createAction('PROJECT-storeQuestionText', props<{templateLookUp: ITemplateGoalLookUp, newQuestionText: string }>());
const storeHasCustomQuestionTextError = createAction('PROJECT-storeHasCustomQuestionTextError', props<{hasError: boolean}>());
const storeProjectType = createAction('PROJECT-storeProjectType', props<{projectType: ProjectType}>());
const getProjectById = createAction('PROJECT-getProjectById', props<{projectId: string }>());
const storeProject = createAction('PROJECT-storeProject', props<{project: IProject }>());
const storeEditProject = createAction('PROJECT-storeEditProject', props<{projectId: string, projectReq: IProjectRequest }>());
const deleteBulkProjects = createAction('PROJECT-deleteBulkProjects', props<{organizationId: string, projectIds: string[] }>());


export const PROJECT_ACTIONS = {
  checkIsLinkValid,
  storeIsLinkValid,
  createProject,
  getProjectWithGoals,
  storeProjectWithGoals,
  storeToggleDimension,
  storeToggleGoalRemove,
  storeToggleGoalAdd,
  storeGoalOnProject,
  storeInstructions,
  postProjectGoals,
  errorSavingProject,
  updateQuestionText,
  storeQuestionText,
  storeHasCustomQuestionTextError,
  storeProjectType,
  getProjectById,
  storeProject,
  storeEditProject,
  deleteBulkProjects,
};
