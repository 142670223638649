import { createAction, props } from '@ngrx/store';
import { IOrgUnit } from '../../../shared/models/org-unit';

const getOrgUnits = createAction('PROJECT-getOrgUnits');
const storeOrgUnits = createAction('PROJECT-storeOrgUnits', props<{ payload: IOrgUnit[] }>());

export const ORGUNIT_ACTIONS = {
  getOrgUnits,
  storeOrgUnits,
};
