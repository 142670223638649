import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BulkDeleteProjectsComponent } from './bulk-delete-projects/bulk-delete-projects.component';
import { CreateProjectComponent } from './create-project/create-project.component';
import { EditProjectGoalComponent } from './edit/edit-project-goal/edit-project-goal.component';
import { EditProjectComponent } from './edit/edit-project/edit-project.component';
import { SelectProjectTypeComponent } from './select-type/select-project-type.component';
import { MassmailComponent } from './massmail/massmail.component';
import { MassmaildetailsComponent } from './massmaildetails/massmaildetails.component';

const routes: Routes = [
  { path: 'project/select-type', component: SelectProjectTypeComponent, data: { name: 'Select Project Type' } },
  { path: 'project/create', component: CreateProjectComponent, data: { name: 'Create Project' } },
  { path: 'project/bulk-delete', component: BulkDeleteProjectsComponent, data: { name: 'Bulk Project Deletion' } },

  // All strictly typed routes with only one slash after project need to be before the dynamic params otherwise Angular will think its an ID.
  {
    path: 'project/:projectId', children: [
      {
        path: 'edit', children: [
          { path: 'goal', component: EditProjectGoalComponent, data: { name: 'Edit Project Goals' } },
          { path: 'settings', component: EditProjectComponent, data: { name: 'Edit Project Settings' } },
        ],
      },
      { path: 'massmail', component: MassmailComponent, data: { name: 'Mass Mailing Status' } },
      { path: 'massmail/:emailId/edit', component: MassmaildetailsComponent, data: { name: 'Edit Mass Mailing' } },
      { path: 'massmail/:copyProjectId/:copyEmailId/create', component: MassmaildetailsComponent, data: { name: 'Copy Mass Mailing' } },
      { path: 'massmail/:emailId/reminder', component: MassmaildetailsComponent, data: { name: 'Create Reminder Mailing' } },
      { path: 'massmail/create', component: MassmaildetailsComponent, data: { name: 'Create New Mass Mailing' } },
      { path: 'massmail/copy', component: MassmaildetailsComponent, data: { name: 'Copy Mass Mailing' } },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class ProjectsRoutingModule { }
