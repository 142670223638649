<div class="d-flex flex-column mt-3">
  <div class="container card">
    <div class="card-body">
      <div class="d-flex">
        <h2 class="card-title">Mass Mailings</h2>
      </div>
      <div class="d-flex mass-mailings">
        <div class="section-left d-inline-block">
          <div class="mass-mailing-text">
            <p>Invite or remind individuals to participate in a survey project by sending a mass mailing.</p>
          </div>
          <div class="create-mailings">
            <button class="btn btn-secondary back-btn" type="submit" (click)="goBack()">
              <i class="bi bi-chevron-left back-arrow"></i>Back</button>
            <button class="btn copy-mailings box-shadow" type="submit" (click)="redirectToCopyDetailsPage()"><i
                class="bi bi-files"></i> Copy
              Mailing</button>
            <button class="btn new-mailing box-shadow" type="submit" (click)="redirectToCreateDetailsPage()"><i
                class="bi bi-plus plus-icon"></i>New
              Mailing</button>
          </div>
          <div class="mailing-list-table">
            <table id="mailingList" class="mailing simple">
              <thead>
                <tr class="BuilderGridHeaderRow">
                  <td class="emptydata">Create Reminder</td>
                  <td class="emptydata"></td>
                  <td class="col-status">Status</td>
                  <td class="col-description">Subject (Description)</td>
                  <td class="col-sentdate">Scheduled Send Date</td>
                  <td class="col-recipients">Recipients</td>
                  <td class="col-undelivered">Undelivered</td>
                  <td class="col-sent">Sent</td>
                  <td class="emptydata"></td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let emailResult of (massMailStatus$ | async).projectEmailResultList; let i = index;"
                  (mouseover)="changeStyle($event, emailResult)" (mouseout)="changeStyle($event, emailResult)"
                  [ngClass]="emailResult.highlightrow">
                  <td><i title="Create Reminder" class="fa-lg bi bi-plus plus-icon create-reminder"
                      (click)="redirectToCreateReminderPage(emailResult)"></i></td>
                  <td *ngIf="!emailResult.parentProjectEmailId" colspan="2"><span
                      class="{{emailResult.status | titlecase}} parentStatus">{{emailResult.status | titlecase}}</span>
                  </td>
                  <td *ngIf="emailResult.parentProjectEmailId"><i class="bi bi-arrow-return-right"></i></td>
                  <td *ngIf="emailResult.parentProjectEmailId"><span
                      class="{{emailResult.status | titlecase}} status">{{emailResult.status | titlecase}}</span>
                  </td>
                  <td>
                    <a class="description-link" href="javascript:void(0)"
                      (click)="redirectToEditDetailsPage(emailResult)">{{emailResult.description}}</a>
                  </td>
                  <td class="nowrap">{{getScheduledDate(emailResult)}}</td>
                  <td class="align-center">{{emailResult.parentProjectEmailId && emailResult.status.toLowerCase() !=
                    'sent' ? 'TBD' : emailResult.numberRecipients}}
                  </td>
                  <td class="align-center">
                    <i title="Download" *ngIf="emailResult.hasUndeliveredFile == true"
                      class="bi bi-filetype-csv enable-icon" (click)="downloadUndeliveredFile(emailResult)"></i>
                  </td>
                  <td class="align-center">{{emailResult.numberDelivered}}</td>
                  <td>
                    <i title="Delete"
                      [ngClass]="{'disable-icon': emailResult.status.toLowerCase() == 'sent', 'enable-icon': emailResult.status.toLowerCase() != 'sent' }"
                      class="bi bi-trash" (click)="openModal(modal, emailResult)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="section-right d-inline-block">
          <div id="InstructionPanel">
            <div>
              <h5 class="status-description">Status Descriptions</h5>
              <p>
                <strong>Draft:</strong> Emails in &quot;draft&quot; status can be edited, both in terms of email text
                and recipient
                list.<strong> Draft emails will not be sent out.</strong>
              </p>
              <p>
                <strong>Scheduled:</strong> Emails with the &quot;scheduled&quot; status will be automatically sent at
                the indicated date and time. You cannot edit mailings that are scheduled. If you need to make changes to
                a scheduled mailing, you must click &quot;Set as Draft&quot; on the Scheduled Mailing screen.
              </p>
              <p>
                <i>
                  Note: When a mailing is sent via Send Now, the Schedule Send Date column will show as
                  &quot;Pending&quot; until the mailing is sent.
                </i>
              </p>
              <p>
                <strong>Sent:</strong> Emails with a &quot;sent&quot; status have been mailed to the recipients. You
                cannot edit sent mailings.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <ng-template #modal let-modal>
    <div class="modal-header align-items-center">
      <h4 class="modal-title">Confirm Delete</h4>
      <span class="p-2 close-modal-icon" (click)="modal.dismiss()"><i class="bi bi-x"></i></span>
    </div>
    <div class="modal-body">
      <p>Are you sure you want to delete?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.dismiss()">Cancel</button>
      <button type="button" class="btn btn-danger" (click)="deleteMassMail()">Delete</button>
    </div>
  </ng-template>
</div>