import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormValidationService } from '../../../shared/services/helpers/form-validation.service';

@Component({
  selector: 'massmail-date-to-send-form',
  templateUrl: './massmail-date-to-send-form.component.html',
  styleUrls: ['./massmail-date-to-send-form.component.scss'],
})
export class MassmailDateToSendFormComponent implements OnInit {
  dateToSendForm = new FormGroup(
    {
      sendDate: new FormControl(''),
      sendTime: new FormControl(''),
    },
    {
      validators: [
      ],
    },
  );

  constructor(
    public formValidationService: FormValidationService,
  ) { }

  ngOnInit(): void { }

  submitDateToSendForm(): void { }

}
