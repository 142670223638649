import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({providedIn: 'root'})
export class FormValidationService {

  validateDates(startDateName: string, endDateName: string, startTimeName: string, endTimeName: string) {
    return (group: FormGroup): {[key: string]: any} => {
      const startControl = group.controls[startDateName];
      const endControl = group.controls[endDateName];
      const startTimeControl = group.controls[startTimeName];
      const endTimeControl = group.controls[endTimeName];

      if (!startControl.value || !endControl.value || !startTimeControl.value || !endTimeControl.value) {
        return null;
      }
      const startDate = Date.parse(startControl.value);
      const endDate = Date.parse(endControl.value);
      // Remove the colon from the time strings to convert to a number
      const startTimeStr = startTimeControl.value.replace(':', '');
      const endTimeStr = endTimeControl.value.replace(':', '');
      // Convert the 24 hour base time to a number. Using 10 as the radix.
      const startTime = parseInt(startTimeStr, 10);
      const endTime = parseInt(endTimeStr, 10);

      // The start and end dates can be the same if the start time is before the end time.
      // Otherwise the start date must be before the end date
      if ((endDate < startDate) || (startDate == endDate && endTime <= startTime)) {
        return {dates: 'Open Date and Time should be before Close Date and Time'};
      }
      return null;
    };
  }

  validateGenericLink(genericLinkName: string) {
    return (group: FormGroup): {[key: string]: any} => {

      const genericLink = group.controls[genericLinkName];

      if (!genericLink.value) {
        return null;
      }

      if (!genericLink.value.match('^[0-9a-z]+$')) {
        return {genericLink: 'Generic link can only contain lowercase letters and numbers'};
      }
      return null;
    };
  }

}