<div class="form-group general-info-container" *ngIf="generalInfo?.state.toLowerCase() != 'copy'">
    <h4 class="heading fw-bold">General Info</h4>
    <label class="info-lbl" for="orgUnit">
        <span class="fw-bold">Department:</span>
        <span>{{generalInfo?.project?.orgUnitName}}</span>
    </label>
    <label class="info-lbl" for="projectName">
        <span class="fw-bold">Project Name:</span>
        <span>{{generalInfo?.project?.name}}</span>
    </label>
    <label class="info-lbl" for="createdBy">
        <span class="fw-bold">Project Created By:</span>
        <span>{{generalInfo?.project?.createdByUsername}}</span>
    </label>
    <label class="info-lbl" for="activeDateRange">
        <span class="fw-bold">Active Date Range:</span>
        <span>{{getDateTimeStr(generalInfo?.project?.dateCreatedEastern)}} -
            {{getDateTimeStr(generalInfo?.project?.closeDateEastern)}}</span>
    </label>
    <ng-container *ngIf="generalInfo?.action.toLowerCase() !== 'create'">
        <label class="info-lbl" for="mailCreatedBy">
            <span class="fw-bold">Mailing Created By:</span>
            <span>{{generalInfo?.mailCreatedBy}}</span>
        </label>
    </ng-container>
    <ng-container *ngIf="generalInfo?.state.toLowerCase() == 'sent'">
        <label class="info-lbl" for="scheduledSendDate">
            <span class="fw-bold">Scheduled Send Date:</span>
            <span>{{getDateTimeStr(generalInfo?.scheduledSendDate)}}</span>
        </label>
        <label class="info-lbl" for="sentDate">
            <span class="fw-bold">Date Sent:</span>
            <span>{{getDateTimeStr(generalInfo?.actualSendDate)}}</span>
        </label>
    </ng-container>
</div>