<form class="address-form" [formGroup]="addressForm" (ngSubmit)="submitAddressForm()">
    <div class="form-group col-sm-10">
        <fieldset [disabled]="isDisabled">
            <div class="form-check">
                <input class="form-check-input" type="radio" (change)="handleChange()" name="addressFrom"
                    formControlName="addressFrom" id="addressFrom1" value="addAddresses" checked>
                <label class="form-check-label" for="addressFrom1">
                    Add Addresses
                </label>
            </div>
        
            <div class="form-check">
                <input class="form-check-input" type="radio" (change)="handleChange()" name="addressFrom"
                    formControlName="addressFrom" id="addressFrom2" value="fromPanel">
                <label class="form-check-label" for="addressFrom2">
                    From Panel
                </label>
            </div>
        
            <div class="form-check">
                <input class="form-check-input" type="radio" (change)="handleChange()" name="addressFrom"
                    formControlName="addressFrom" id="addressFrom4" value="existingMailing">
                <label class="form-check-label" for="addressFrom">
                    Existing Mailing
                </label>
            </div>
        </fieldset>
    </div>
    <div class="form-group col-sm-11 mt-3" *ngIf="addressForm.controls['addressFrom'].value == 'addAddresses'">
        <label for="addressList">Address</label>
        <fieldset [disabled]="isDisabled">
            <textarea class="form-control" formControlName="addressList" id="addressList"
                placeholder="Enter addresses (Allowed delimiters include: commas, semi-colons, spaces and new lines)"></textarea>
        </fieldset>
    </div>
    <div class="form-group col-sm-6 mt-3" *ngIf="addressForm.controls['addressFrom'].value == 'fromPanel'">
        <div class="form-group col-sm-12">
            <label for="fromName" class="addresses-label">Select Panel</label>
            <select class="form-control" id="selectedPanel" (change)="selectPanel()"
                formControlName="selectedPanel" required>
                <option *ngFor="let panel of (panels$ | async) | sort:'asc':'panelName'; let i = index;" [ngValue]="panel">
                    {{ panel?.panelName}}</option>
            </select>
        </div>
        <ng-container *ngIf="addressForm.controls['selectedPanel'].value && panelRecipients">
            <div class="form-group col-sm-12">
                <label><b>Name:</b> {{addressForm.controls['selectedPanel'].value?.panelName}} </label>
            </div>
            <div class="form-group col-sm-12">
                <label><b>Created By:</b> {{addressForm.controls['selectedPanel'].value?.createdBy }} </label>
            </div>
            <div class="form-group col-sm-12">
                <label><b>Date Created:</b> {{addressForm.controls['selectedPanel'].value?.dateCreated }} </label>
            </div>
            <div class="form-group col-sm-12">
                <label><b>Address Count:</b> {{panelRecipients.length}}</label>
            </div>
        </ng-container>
    </div>
    <div class="form-group col-sm-12 mt-3" *ngIf="addressForm.controls['addressFrom'].value == 'existingMailing'">
        <div class="form-group col-sm-6">
            <label for="selectedProject" class="addresses-label">Select Project</label>
            <select class="form-control" [attr.disabled]="isDisabled ? 'disabled' : null" id="selectedProject" (change)="selectProject($event)"
                formControlName="selectedProjectId" required>
                <option *ngFor="let project of (projectList$ | async) | sort:'asc':'name'; let i = index;" [value]="project.projectId">
                    {{ project?.name}}</option>
            </select>
        </div>
        <div class="form-group col-sm-6">
            <label for="selectedMailing" class="addresses-label">Select Mailing</label>
            <select class="form-control" id="selectedMailing" (change)="getEmailAddressFromMailing()"
                formControlName="selectedMailing" required>
                <option *ngFor="let emailResult of (massMailStatus$ | async)?.projectEmailResultList | sort:'asc':'description' ;"
                    [value]="emailResult.projectEmailId">{{ emailResult?.description}}</option>
            </select>
        </div>
        <ng-container *ngIf="addressForm.controls['selectedMailing'].value && selectedEmailResult">
            <div class="form-group col-sm-12">
                <label><b>Subject:</b> {{selectedEmailResult?.subject}} </label>
            </div>
            <div class="form-group col-sm-12">
                <label><b>Created By:</b> {{selectedEmailResult?.createdByUsername}} </label>
            </div>
            <div class="form-group col-sm-12">
                <label><b>Date Created:</b> {{selectedEmailResult?.dateCreated }} </label>
            </div>
            <div class="form-group col-sm-12">
                <label><b>Address Count:</b> {{selectedEmailResult?.recipientList.length}}</label>
            </div>
        </ng-container>
    </div>
    <div class="form-group col-sm-12">
        <div class="col-sm-6 d-inline-block pl-0 text-left">
            <button [attr.disabled]="isDisabled ? 'disabled' : null" *ngIf="addressForm.controls['addressFrom'].value == 'addAddresses'"
                class="btn add-emails light-blue-btn box-shadow d-inline-block" (click)="addEmailAddress()">Add
                Addresses</button>
            <button [attr.disabled]="isDisabled ? 'disabled' : null" *ngIf="addressForm.controls['addressFrom'].value == 'fromPanel'"
                class="btn add-emails light-blue-btn box-shadow d-inline-block" (click)="addEmailAddressFromPanel()">Add
                Addresses</button>
            <button [attr.disabled]="isDisabled ? 'disabled' : null" *ngIf="addressForm.controls['addressFrom'].value == 'existingMailing'"
                class="btn add-emails light-blue-btn box-shadow d-inline-block"
                (click)="addEmailAddressFromMailing()">Add Addresses</button>
        </div>
        <div class="col-sm-6 d-inline-block pl-0 pr-0 text-right">

        </div>
    </div>
    <div class="form-group col-sm-12">
        <div class="table-header">
            <h4 class="header-text">Included Email Address ({{ getAddressCount() }})</h4>
            <input type="text" placeholder="Search Address" class="form-control search-text" 
         formControlName="searchAddress" id="searchAddress"  (ngModelChange)="searchRecipient()">
            <button [attr.disabled]="isRemoveEmailDisabled ? 'disabled' : null" class="btn btn-link delete-all-btn" type="button" (click)="removeEmail()">Delete All</button>
        </div>
        <address-list [isDisabled]="isRemoveEmailDisabled" [selectedEmailAddressList]="addressForm.get('replyToAddress').value"
            (onRemoveEmail)="removeEmail($event)"></address-list>
    </div>
</form>
