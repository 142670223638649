import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProjectsModule } from './projects/projects.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { WithCredentialsInterceptor } from './shared/services/interceptors/with-credentials-interceptor';
import { Ng2TopbarModule } from '@campuslabs/library-top-bar-ng2';
import { QuillModule } from 'ngx-quill';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EFFECTS, REDUCERS } from './store/stores';
import { SharedModule } from './shared/shared.module';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

import Quill from 'quill';
// Register the custom blot for the horizontal line
const Parchment = Quill.import('parchment');
const BlockEmbed = Quill.import('blots/block/embed');

class HorizontalLineBlot extends BlockEmbed { }
HorizontalLineBlot['blotName'] = 'hr';
HorizontalLineBlot['tagName'] = 'hr';
Quill.register({
  'formats/hr': HorizontalLineBlot
});
const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true },
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    Ng2TopbarModule,
    ProjectsModule,
    SharedModule,
    StoreModule.forRoot(REDUCERS),
    EffectsModule.forRoot(EFFECTS),
    QuillModule.forRoot({
      // customModules: [{
      //   implementation: Counter,
      //   path: 'modules/counter'
      // }],
      customOptions: [
        {
          import: "formats/font",
          whitelist: [
            "mirza",
            "roboto",
            "aref",
            "serif",
            "sansserif",
            "monospace"
          ]
        }
      ]
    }),
  ],
  providers: [
    ...httpInterceptorProviders,
    // Track uncaught exceptions
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
