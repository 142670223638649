import { Component, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IOrganization } from '../../shared/models/organization';
import { ORGANIZATION_ACTIONS } from '../store/organization/organization.action';
import { ORGANIZATION_SELECTORS } from '../store/organization/organization.reducer';
import { PROJECT_ACTIONS } from '../store/project/project.actions';

@Component({
  selector: 'app-bulk-delete-projects',
  templateUrl: './bulk-delete-projects.component.html',
  styleUrls: ['./bulk-delete-projects.component.scss'],
})
export class BulkDeleteProjectsComponent {
  organizations$: Observable<IOrganization[]>;

  bulkDeleteForm = new FormGroup(
    {
      organization: new FormControl(null),
      projectIds: new FormControl(''),
    },
  );

  constructor(
    private store: Store,
    private modalService: NgbModal,
  ) {
    this.store.dispatch(ORGANIZATION_ACTIONS.getOrganizations());
    this.organizations$ = this.store.select(ORGANIZATION_SELECTORS.getOrganizations);
  }

  openModal(modal: TemplateRef<NgbModal>): void {
    this.modalService.open(modal, { centered: true });
  }

  cancel(): void {
    history.back();
  }

  deleteProjects(): void {
    this.modalService.dismissAll();
    const organizationId = this.bulkDeleteForm.get('organization').value;
    const projectIds = this.bulkDeleteForm.get('projectIds').value.split(/[ ,\r\n\t]+/);
    this.store.dispatch(PROJECT_ACTIONS.deleteBulkProjects({ organizationId, projectIds }));

    // Clear form incase user has more projects to delete.
    this.bulkDeleteForm.get('organization').setValue(null);
    this.bulkDeleteForm.get('projectIds').setValue('');
  }

}
