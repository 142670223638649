<form [formGroup]="dateToSendForm" (ngSubmit)="submitDateToSendForm()">
    <div class="form-group">
        <label for="sendDate">Send Date<span class="ml-1 required">*</span></label>
        <input type="date" formControlName="sendDate" name="sendDate" class="form-control" id="sendDate" required>
    </div>
    <div class="form-group">
        <label for="sendTime">Send Time<span class="ml-1 required">*</span></label>
        <input type="time" formControlName="sendTime" name="sendTime" class="form-control" id="sendTime" required>
      </div>
</form>
