import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { BaseSubscriptionComponent } from '../../../shared/BaseSubscriptionComponent';
import { ProjectEmailCadence } from '../../../shared/enums/project-email-cadence';
import { ProjectType } from '../../../shared/enums/project-type';
import { IOrgUnit } from '../../../shared/models/org-unit';
import { IProject, IProjectRequest } from '../../../shared/models/project';
import { FormValidationService } from '../../../shared/services/helpers/form-validation.service';
import { UserSettingsService } from '../../../shared/services/user-settings.service';
import { ORGUNIT_ACTIONS } from '../../store/orgUnit/orgUnit.actions';
import { ORGUNIT_SELECTORS } from '../../store/orgUnit/orgUnit.reducer';
import { PROJECT_ACTIONS } from '../../store/project/project.actions';
import { PROJECT_SELECTORS } from '../../store/project/project.reducer';

@Component({
  selector: 'survey-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
})
export class ProjectFormComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() editProject: IProject;
  @Output() saveProject = new EventEmitter<IProjectRequest>();

  projectForm = new FormGroup(
    {
      name: new FormControl(''),
      department: new FormControl(null),
      openDate: new FormControl(''),
      openTime: new FormControl(''),
      closeDate: new FormControl(),
      closeTime: new FormControl(''),
      notes: new FormControl(''),
      genericLink: new FormControl(''),
      emailCadence: new FormControl(ProjectEmailCadence.None), // default to None cadence
      hideIdentityInReport: new FormControl(null),
      hideResultsInCrossProjectReporting: new FormControl(null),
      hideQuestionNumbers: new FormControl(null),
      hideProgressBar: new FormControl(null),
    },
    {
      validators: [
        this.formValidationService.validateDates('openDate', 'closeDate', 'openTime', 'closeTime'),
        this.formValidationService.validateGenericLink('genericLink'),
      ],
    },
  );

  cadenceOptions = [
    {
      value: ProjectEmailCadence.None,
      label: 'None',
    },
    {
      value: ProjectEmailCadence.EveryResponse,
      label: 'Every Response',
    },
    {
      value: ProjectEmailCadence.EveryDay,
      label: 'Daily Digest',
    },
    {
      value: ProjectEmailCadence.EveryWeek,
      label: 'Weekly Digest',
    },
  ];

  isLinkValid$: Observable<boolean>;
  isCheckingGenericLink$: Observable<boolean>;
  orgUnits$: Observable<IOrgUnit[]>;

  constructor(
    public formValidationService: FormValidationService,
    public userSettingsService: UserSettingsService,
    private store: Store,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(ORGUNIT_ACTIONS.getOrgUnits());

    const open = new Date();
    this.projectForm.controls.openDate.setValue(formatDate(open,'yyyy-MM-dd','en'));
    this.projectForm.controls.openTime.setValue(formatDate(open,'HH:mm','en'));

    const close = new Date(open.getFullYear(), open.getMonth(), open.getDate()+14, open.getHours(), open.getMinutes(), open.getSeconds());
    this.projectForm.controls.closeDate.setValue(formatDate(close,'yyyy-MM-dd','en'));
    this.projectForm.controls.closeTime.setValue(formatDate(close,'HH:mm','en'));

    //listen for changes to the genericLink field
    this.projectForm.controls.genericLink.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe),
    ).subscribe((link: string) => {
      //check whether the project link is available
      this.store.dispatch(PROJECT_ACTIONS.checkIsLinkValid({link, projectId: this.editProject?.projectId }));
    });

    this.orgUnits$ = this.store.pipe(select(ORGUNIT_SELECTORS.getOrgUnits));

    // These need to be ok with current project Id for editing.
    this.isLinkValid$ = this.store.pipe(select(PROJECT_SELECTORS.getIsLinkValid));
    this.isCheckingGenericLink$ = this.store.select(PROJECT_SELECTORS.getIsCheckingGenericLink);

    if (this.editProject) {
      this.projectForm.get('name').setValue(this.editProject.name);
      this.projectForm.get('department').setValue(this.editProject.orgUnitId);
      this.projectForm.get('openDate').setValue(this.editProject.openDateEasternDate);
      this.projectForm.get('openTime').setValue(this.editProject.openDateEasternTime);
      this.projectForm.get('closeDate').setValue(this.editProject.closeDateEasternDate);
      this.projectForm.get('closeTime').setValue(this.editProject.closeDateEasternTime);
      this.projectForm.get('notes').setValue(this.editProject.notes);
      this.projectForm.get('emailCadence').setValue(this.editProject.emailCadence);
      this.projectForm.get('hideIdentityInReport').setValue(this.editProject.hideIdentityInReport);
      this.projectForm.get('hideResultsInCrossProjectReporting').setValue(this.editProject.hideResultsInCrossProjectReporting);
      this.projectForm.get('hideQuestionNumbers').setValue(this.editProject.hideQuestionNumbers);
      this.projectForm.get('hideProgressBar').setValue(this.editProject.hideProgressBar);
    }
  }

  cancel(): void {
    history.back();
  }

  submitProject(): void {
    // Convert local date and time to DateTimeUtc
    // const openDateUtc = new Date(`${this.projectForm.get('openDate').value}T${this.projectForm.get('openTime').value}`).toISOString();
    // const closeDateUtc = new Date(`${this.projectForm.get('closeDate').value}T${this.projectForm.get('closeTime').value}`).toISOString();

    const openDateUtc = `${this.projectForm.get('openDate').value}T${this.projectForm.get('openTime').value}`;
     const closeDateUtc =`${this.projectForm.get('closeDate').value}T${this.projectForm.get('closeTime').value}`;

    this.store.select(PROJECT_SELECTORS.getProjectType).pipe(take(1)).subscribe((type: ProjectType) => {
      const newProject: IProjectRequest = {
        name: this.projectForm.get('name').value,
        orgUnitId: this.projectForm.get('department').value,
        openDateUtc,
        closeDateUtc,
        notes: this.projectForm.get('notes').value,
        genericLink: this.projectForm.get('genericLink').value,
        type,
        emailCadence: this.projectForm.get('emailCadence').value,
        hideIdentityInReport: this.projectForm.get('hideIdentityInReport').value || false,
        hideResultsInCrossProjectReporting: this.projectForm.get('hideResultsInCrossProjectReporting').value || false,
        hideQuestionNumbers: this.projectForm.get('hideQuestionNumbers').value || false,
        hideProgressBar: this.projectForm.get('hideProgressBar').value || false,
      };

      this.saveProject.emit(newProject);
    });
  }
}
