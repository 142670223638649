import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
})
export class TimeComponent implements OnInit {
  @Output() onTimeChange = new EventEmitter<any>();
  @Input() selectedDateTime: any;
  @Input() isDisabled: boolean;
  disabled = false;

  timeForm = new FormGroup(
    { selectedTime: new FormControl('') },
    { validators: [] },
  );
  constructor() { }

  ngOnInit(): void {
    this.disabled = this.isDisabled;
  }

  ngOnChanges(changes: any) {
    if (changes['selectedDateTime']) {
      if (this.selectedDateTime) {
        const momentObject = moment(this.selectedDateTime);
        this.timeForm.controls['selectedTime'].setValue(momentObject.format('HH:mm'));
      } else {
        this.timeForm.controls['selectedTime'].setValue(null);
      }
    }
    if (changes['isDisabled']) {
      this.disabled = this.isDisabled;
    }
  }

  emitTimeChange(): void {
    this.onTimeChange.emit(this.timeForm.controls['selectedTime'].value);
  }

  submitTimeForm(): void { }

}
