import { Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { RecipientList } from '../../../shared/models/mass-mail-form';
import { Store, select } from '@ngrx/store';
import { MASSMAIL_ACTIONS } from '../../store/massmail/massmail.actions';
import { MASSMAIL_SELECTORS } from '../../store/massmail/massmail.reducer';
import { Observable } from 'rxjs';
import { IMassMailStatus } from '../../../shared/models/mass-mail-status';
import { IProjectEmailResult } from '../../../shared/models/project-email-result';

@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddressFormComponent implements OnInit {
  @Output() onRecipientListChange = new EventEmitter<any>();
  @Input() recipientList: RecipientList[];
  @Input() projectId: string;
  @Input() isDisabled: boolean;
  @Input() isRemoveEmailDisabled: boolean;

  projectList$: Observable<any>;
  panels$: Observable<any>;
  panelRecipients: RecipientList[] = [];
  massMailStatus$: Observable<IMassMailStatus>;
  emailResult$: Observable<IProjectEmailResult>;
  selectedEmailResult: IProjectEmailResult = null;
  searchedRecipientList : RecipientList[];
  addressForm: FormGroup = new FormGroup(
    {
      addressList: new FormControl(''),
      replyToAddress: new FormControl(''),
      addressFrom: new FormControl(''),
      searchAddress: new FormControl(''),
      selectedProjectId: new FormControl(null),
      selectedMailing: new FormControl(null),
      selectedPanel: new FormControl(null),
    },
    {
      validators: [

      ],
    },
  );
  constructor(
    private store: Store,
  ) { }
  ngOnInit(): void {
    this.addressForm.get('addressFrom').setValue('addAddresses');
    this.addressForm.get('replyToAddress').setValue(this.recipientList);
    this.searchedRecipientList = this.recipientList;
  }

  ngOnChanges(changes: any): void {
    if (changes['recipientList']) {
      this.addressForm.get('replyToAddress').setValue(this.recipientList);
    }
  }
;
  searchRecipient(){
    const emailStr = this.addressForm.get('searchAddress').value;
    if(emailStr){
      this.recipientList = this.searchedRecipientList.filter(recipient => recipient.emailAddress.indexOf(emailStr) >= 0);
    } else{
      this.recipientList= this.searchedRecipientList;
    }
    this.addressForm.get('replyToAddress').setValue(this.recipientList);
  }

  addEmailAddress(): void {
    const emailStr = this.addressForm.get('addressList').value;
    if (emailStr) {
      // The filter removes any empty responses
      const emailIds: string[] = emailStr.split(/[ ,;\r\n\t]+/).filter(email => email).map(email => email.toLowerCase());
      let uniqueEmails = [...new Set(emailIds)];
      const emailList: RecipientList[] = uniqueEmails.map(email => {
        return { userId: null, emailAddress: email};
      });
      this.setEmailList(emailList);
    }
  }

  setEmailList(emailList: RecipientList[]): void {
    if (emailList && emailList.length) {
      let existingList: RecipientList[] = this.addressForm.get('replyToAddress').value;
      const newList: RecipientList[] = [];
      if (existingList && existingList.length) {
        emailList.forEach(email => {
          let isExists = false;
          for(let index = 0; index < existingList.length; index++) {
            const existingEmail = existingList[index];
            if (email.emailAddress.toLowerCase() == existingEmail.emailAddress.toLowerCase()) {
              isExists = true;
              break;
            }
          }

          if (!isExists) {
            newList.push(email);
          }
        });
        existingList = existingList.concat(newList);
        this.searchedRecipientList = existingList;
        this.addressForm.get('replyToAddress').setValue(existingList);
        this.addressForm.get('addressList').setValue(null);
        this.emitRecipientsChange();
      } else {
        this.searchedRecipientList = emailList;
        this.addressForm.get('replyToAddress').setValue(emailList);
        this.addressForm.get('addressList').setValue(null);
        this.emitRecipientsChange();
      }
    }
  }

  getAddressCount(): number {
    const emailList = this.addressForm.get('replyToAddress').value;
    return emailList ? emailList.length : 0;
  }

  addEmailAddressFromPanel(): void {
    this.setEmailList(this.panelRecipients);
  }

  getEmailAddressFromMailing(): void {
    const selectedMailing = this.addressForm.get('selectedMailing').value;
    const selectedProjectId = this.addressForm.get('selectedProjectId').value;
    if (selectedProjectId && selectedMailing) {
      this.store.dispatch(MASSMAIL_ACTIONS.getMassMailByEmailId({ projectId: +selectedProjectId, emailId: +selectedMailing }));
      this.emailResult$ = this.store.select(MASSMAIL_SELECTORS.getMassMailByEmailId);
      this.emailResult$.subscribe((result: IProjectEmailResult) => {
        if (result.projectId == selectedProjectId && result.recipientList) {
          this.selectedEmailResult = result;
        }
      });
    }
  }

  addEmailAddressFromMailing(): void {
    const selectedMailing = this.addressForm.get('selectedMailing').value;
    const selectedProjectId = this.addressForm.get('selectedProjectId').value;
    if (selectedProjectId && selectedMailing && this.selectedEmailResult) {
      this.setEmailList(this.selectedEmailResult.recipientList);
    }
  }

  handleChange(): void {
    const addressFrom = this.addressForm.get('addressFrom').value;
    if (addressFrom == 'existingMailing') {
      this.store.dispatch(MASSMAIL_ACTIONS.getAllProjects());
      this.projectList$ = this.store.pipe(select(MASSMAIL_SELECTORS.getAllProjects));
    } else if (addressFrom == 'fromPanel') {
      this.store.dispatch(MASSMAIL_ACTIONS.getPanelsByProjectId({ projectId: +this.projectId }));
      this.panels$ = this.store.select(MASSMAIL_SELECTORS.getPanelsByProjectId);
    }
  }

  emitRecipientsChange(): void {
    this.onRecipientListChange.emit(this.addressForm.get('replyToAddress').value);
  }

  removeEmail(email: RecipientList = null): void {
    if (email) {
      let existingList: RecipientList[] =  this.searchedRecipientList;
      if (existingList && existingList.length) {
        this.searchedRecipientList = existingList.filter(item=>item.emailAddress!= email.emailAddress);
         existingList = existingList.filter(item => item.emailAddress != email.emailAddress);
         this.addressForm.get('replyToAddress').setValue(existingList);
        this.emitRecipientsChange();
      }
    } else {
      this.addressForm.get('replyToAddress').setValue(null);
      this.emitRecipientsChange();
    }
  }

  selectProject(): void {
    const selectedProjectId = this.addressForm.get('selectedProjectId').value;
    this.store.dispatch(MASSMAIL_ACTIONS.getMassMailByProjectId({ projectId: +selectedProjectId }));
    this.massMailStatus$ = this.store.pipe(select(MASSMAIL_SELECTORS.getMassMail));
    this.addressForm.get('selectedMailing').setValue(null);
    this.selectedEmailResult = null;
  }

  selectPanel(): void {
    this.addressForm.get('selectedMailing').setValue(null);
    this.selectedEmailResult = null;
    const selectedPanel = this.addressForm.get('selectedPanel').value;
    if (selectedPanel) {
      this.panelRecipients = selectedPanel.projectEmailRecipients;
    }
  }

  submitAddressForm(): void {
  }
}
