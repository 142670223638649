import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IMessage } from '../../shared/models/message';
import { MESSAGE_ACTIONS } from './message.actions';


/*
  State
*/
export interface IMessageState {
  message: IMessage;
  showMessage: boolean,
}
export const initialState: IMessageState = {
  message: null,
  showMessage: false,
};


/*
  Reducer
*/
export const messageReducer = createReducer(
  initialState,
  on(MESSAGE_ACTIONS.showMessage, (state, { payload }) => {
    return {
      message: payload,
      showMessage: true,
    };
  }),
  on(MESSAGE_ACTIONS.hideMessage, (state, { messageId }) => {
    //if we're trying to hide a different message, don't do that
    if (state.message.id !== messageId) { return state; }

    //hide the message if the id matches
    return {
      ...state,
      showMessage: false,
    };
  }),
);


/*
  Selectors
*/
const getMessageState = createFeatureSelector<IMessageState>('message');
const getMessage = createSelector(
  getMessageState,
  (state: IMessageState) => state?.message,
);
const getShowMessage = createSelector(
  getMessageState,
  (state: IMessageState) => state?.showMessage,
);
export const MESSAGE_SELECTORS = {
  getMessage,
  getShowMessage,
};