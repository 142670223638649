import { createAction, props } from '@ngrx/store';

const finishAppInsightsAction = createAction('APP-INSIGHTS-finishAppInsightsAction');
const startAppInsights = createAction('APP-INSIGHTS-startAppInsights');
const trackEvent = createAction('APP-INSIGHTS-trackEvent', props<{name: string, properties?: { [key: string]: any; }}>());

export const APP_INSIGHTS_ACTIONS = {
  finishAppInsightsAction,
  startAppInsights,
  trackEvent,
};
