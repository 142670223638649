import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { MASSMAIL_ACTIONS } from './massmail.actions';
import { IMassMailStatus } from '../../../shared/models/mass-mail-status';

export interface IMassMailState {
  massMail: IMassMailStatus,
  editMassMail: any,
  projects: any,
  project: any,
  panels: any,
  recipientList: any,
  emailresult: any,
  undeliveredFile: any,
}

export const initialState: IMassMailState = {
  massMail: {
    sendSelfServiceMailings: false,
    projectEmailResultList: [],
  },
  editMassMail: {},
  projects: {},
  project: {},
  panels: {},
  recipientList: {},
  emailresult: {},
  undeliveredFile: {},
};


export const massmailReducer = createReducer(
  initialState,

  on(MASSMAIL_ACTIONS.storeMassMail, (state, { massMail }) => {
    return {
      ...state,
      massMail,
    };
  }),
  on(MASSMAIL_ACTIONS.storeMassMailByEmailId, (state, { editMassMail }) => {
    return {
      ...state,
      editMassMail: { ...editMassMail },
    };
  }),
  on(MASSMAIL_ACTIONS.storeAllProjects, (state, { projects }) => {
    return {
      ...state,
      projects,
    };
  }),
  on(MASSMAIL_ACTIONS.storeProject, (state, { project }) => {
    return {
      ...state,
      project,
    };
  }),
  on(MASSMAIL_ACTIONS.storePanels, (state, { panels }) => {
    return {
      ...state,
      panels,
    };
  }),
  on(MASSMAIL_ACTIONS.storeRecipientList, (state, { recipientList }) => {
    return {
      ...state,
      recipientList,
    };
  }),
  on(MASSMAIL_ACTIONS.storePreviewMailingFormat, (state, { emailResult }) => {
    return {
      ...state,
      emailResult,
    };
  }),
  on(MASSMAIL_ACTIONS.storeUndeliveredFile, (state, { undeliveredFile }) => {
    return {
      ...state,
      undeliveredFile,
    };
  }),
);


const getState = createFeatureSelector<IMassMailState>('massMail');

const getMassMailFunc = (state: IMassMailState) => state.massMail;

const getMassMailByEmailIdFunc = (state: IMassMailState) => state.editMassMail;

const getMassMailReminderByEmailIdFunc = (state: IMassMailState) => state.editMassMail;

const getProjectByProjectIdFunc = (state: any) => state.project;


const getPanelsByProjectIdFunc = (state: any) => state.panels;

const getAllProjectsFunc = (state: any) => {
  return state.projects;
};

const getRecipientListByPanelIdFunc = (state: any) => state.recipientList;

const getPreviewMailingFunc = (state: any) => state.emailResult;

const getUndeliveredFileFunc = (state: any) => state.undeliveredFile;

const getMassMail = createSelector(
  getState,
  getMassMailFunc,
);

const getMassMailByEmailId = createSelector(
  getState,
  getMassMailByEmailIdFunc,
);

const getMassMailReminderByEmailId = createSelector(
  getState,
  getMassMailReminderByEmailIdFunc,
);

const getAllProjects = createSelector(
  getState,
  getAllProjectsFunc,
);

const getProjectByProjectId = createSelector(
  getState,
  getProjectByProjectIdFunc,
);

const getPanelsByProjectId = createSelector(
  getState,
  getPanelsByProjectIdFunc,
);

const getRecipientListByPanelId = createSelector(
  getState,
  getRecipientListByPanelIdFunc,
);

const getPreviewMailing = createSelector(
  getState,
  getPreviewMailingFunc,
);

const getUndeliveredFile = createSelector(
  getState,
  getUndeliveredFileFunc,
);

export const MASSMAIL_SELECTORS = {
  getMassMail,
  getMassMailByEmailId,
  getMassMailReminderByEmailId,
  getAllProjects,
  getProjectByProjectId,
  getPanelsByProjectId,
  getRecipientListByPanelId,
  getPreviewMailing,
  getUndeliveredFile,
};
