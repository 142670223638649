import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "projects/surveys/src/environments/environment";
import { Observable, of } from "rxjs";
import { ITemplateGoal } from "../../models/template-goal";
import { BaseHttpService } from "./base-http.service";

@Injectable({
  providedIn: 'root'
})
export class TemplateGoalHttpService extends BaseHttpService {
  constructor(h: HttpClient) {
    super(h);
  }

  private _buildUrl(append: string = ''): string {
    return `${environment.BASELINE_API}/community/goals/${append}`;
  }

  getAllTemplateGoals(): Observable<ITemplateGoal[]> {
    return this.getRequest({
      url: this._buildUrl(),
      query: {}
    });
  }
}