import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { resultMemoize, Store } from '@ngrx/store';
import { MASSMAIL_ACTIONS } from '../store/massmail/massmail.actions';
import { Observable, take } from 'rxjs';
import { IProjectEmailResult, IRecipient } from '../../shared/models/project-email-result';
import { MASSMAIL_SELECTORS } from '../store/massmail/massmail.reducer';
import { IProject } from '../../shared/models/project';

@Component({
  selector: 'app-massmaildetails',
  templateUrl: './massmaildetails.component.html',
  styleUrls: ['./massmaildetails.component.scss'],
})
export class MassmaildetailsComponent implements OnInit {
  emailAction: string;
  copyRecipients = true;
  isCopy = false;
  isReminder = false;
  emailResult$: Observable<IProjectEmailResult>;
  project$: Observable<IProject>;
  formConfig: any = {};
  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.queryParamMap.pipe(take(1)).subscribe(queryParamMap => {
      this.copyRecipients = !(queryParamMap.has('copyRecipients') && queryParamMap.get('copyRecipients') === 'false');
    });
    this.route.paramMap.pipe(take(1)).subscribe(paramMap => {
      this.emailAction = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
      this.isCopy = this.emailAction == 'copy';
      this.isReminder = this.emailAction == 'reminder';
      this.formConfig.projectId = +paramMap.get('projectId');
      this.formConfig.emailId = +paramMap.get('emailId');
      this.formConfig.state = 'draft';
      this.formConfig.emailCreatedBy = '';

      if (this.formConfig.emailId) {
        if (this.isReminder) {
          this.store.dispatch(MASSMAIL_ACTIONS.getMassMailReminderByEmailId({ projectId: this.formConfig.projectId, emailId: this.formConfig.emailId }));
          this.emailResult$ = this.store.select(MASSMAIL_SELECTORS.getMassMailReminderByEmailId);
        } else {
          this.store.dispatch(MASSMAIL_ACTIONS.getMassMailByEmailId({ projectId: this.formConfig.projectId, emailId: this.formConfig.emailId }));
          this.emailResult$ = this.store.select(MASSMAIL_SELECTORS.getMassMailByEmailId);
        }

        this.emailResult$.subscribe((result: IProjectEmailResult) => {
          if (result.projectEmailId == this.formConfig.emailId && result.projectId == this.formConfig.projectId) {
            this.formConfig.emailCreatedBy = result.createdByUsername;
            this.formConfig.recipientList = result.recipientList;
            this.formConfig.emailResult = { ...result };
            this.formConfig.project = result.project;
            this.formConfig.ProjectOpenDate = result.project.openDateEastern;
            this.formConfig.ProjectCloseDate = result.project.closeDateEastern;

            if (this.emailAction == 'reminder') {
              // Reminder actions copy the specified email into a new one, and set the parent email ID as the original email id
              // This links the created email as a reminder to the original
              this.formConfig.emailResult.parentProjectEmailId = result.projectEmailId;
              this.formConfig.emailResult.reminder = true;
              this.formConfig.emailId = null;

              this.isReminder = true;

              if (!result.description || result.description.length <= 0) {
                this.formConfig.emailResult.description = 'Reminder';
              }
              else if (result.description.toLowerCase().indexOf('reminder') < 0) {
                this.formConfig.emailResult.description = result.description + ' - Reminder';
              }
            }
            if (this.emailAction == 'edit') {
              this.formConfig.state = result.state.toLowerCase();
            }
            else {
              this.formConfig.state = 'draft';
              this.formConfig.emailResult.state = 'DRAFT';
            }
            this.isReminder = this.formConfig.emailResult.parentProjectEmailId ? true : false;
          }
        });
      }
      if (this.emailAction == 'create') {
        // create actions have 2 states: either a new email or a copy of an existing email. The copy passes the IDs
        const copyProjectId = +paramMap.get('copyProjectId');
        const copyEmailId = +paramMap.get('copyEmailId');

        this.formConfig.emailCreatedBy = '';
        if (copyProjectId && copyEmailId) {
          this.isCopy = true;
          // These are set from copymail-form when copying an email. Read in the values and set them in the form.
          this.store.dispatch(MASSMAIL_ACTIONS.getMassMailByEmailId({ projectId: copyProjectId, emailId: copyEmailId }));
          this.emailResult$ = this.store.select(MASSMAIL_SELECTORS.getMassMailByEmailId);
          this.emailResult$.subscribe((result: IProjectEmailResult) => {
            if (result.projectEmailId == copyEmailId && result.projectId == copyProjectId) {
              this.formConfig.emailResult = { ...result };
              // Set the parent email ID to null so this won't show as a reminder (the users can create new emails from reminders)
              this.formConfig.emailResult.parentProjectEmailId = null;
              // Change the project ID to match our current project (since we can copy emails from other projects)
              this.formConfig.emailResult.projectId = this.formConfig.projectId;
              this.formConfig.emailResult.projectEmailStateId = 1;
              this.formConfig.emailResult.state = 'DRAFT';
              this.formConfig.state = 'draft';
              // Set the email ID to null so we don't overwrite the original email
              this.formConfig.emailId = null;
              // If we are copying the recipients, then set them in the list here
              if (this.copyRecipients) {
                this.formConfig.recipientList = result.recipientList;
              } else {
                this.formConfig.recipientList = this.formConfig.emailResult.recipientList = [] as IRecipient[];
              }
              this.isReminder = false;
            }
          });
        }

        //On Create, grab Project info to populate Date validation
        this.store.dispatch(MASSMAIL_ACTIONS.getProjectByProjectId({ projectId: this.formConfig.projectId }));
        this.project$ = this.store.select(MASSMAIL_SELECTORS.getProjectByProjectId);
        this.project$.subscribe((result: IProject) => {
          if (result.projectId == this.formConfig.projectId) {
            this.formConfig.project = result;
            this.formConfig.ProjectOpenDate = result.openDateEastern;
            this.formConfig.ProjectCloseDate = result.closeDateEastern;
          }
        });
      }
    });
  }

  getTitle(): string {
    if (this.formConfig) {
      if (this.isCopy) {
        return 'Copy Mailing';
      } else if (this.emailAction == 'reminder') {
        return 'Create Reminder';
      } else if (!this.isCopy && this.emailAction != 'reminder') {
        if (this.formConfig?.state && this.formConfig.state.toLowerCase() == 'sent') {
          return this.isReminder ? 'Sent Reminder' : 'Sent Mailing';
        } else if (this.formConfig.emailId && this.formConfig.emailId > 0) {
          if (this.formConfig.state.toLowerCase() == 'scheduled') {
            return this.isReminder ? 'Scheduled Reminder' : 'Scheduled Mailing';
          } else {
            return this.isReminder ? 'Edit Reminder' : 'Edit Mailing';
          }
        } else {
          return this.isReminder ? 'New Reminder' : 'New Mailing';
        }
      }
    }
    return '';
  }
}
