import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ITemplateGoal } from '../../shared/models/template-goal';
import { TemplateGoalHttpService } from '../../shared/services/communication/template-goal-http.service';
import { MESSAGE_ACTIONS } from '../message/message.actions';
import { TEMPLATE_GOAL_ACTIONS } from './template-goal.actions';

@Injectable()
export class TemplateGoalEffects {

  constructor(
    private actions$: Actions,
    private templateGoalHttpService: TemplateGoalHttpService,
  ) {}


  storeTemplateGoals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TEMPLATE_GOAL_ACTIONS.getTemplateGoals),
      mergeMap(() =>
        this.templateGoalHttpService.getAllTemplateGoals().pipe(
          map((templateGoals: ITemplateGoal[]) =>
            TEMPLATE_GOAL_ACTIONS.storeTemplateGoals({ payload: templateGoals }),
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(MESSAGE_ACTIONS.danger({ errorResponse, loaderKey: TEMPLATE_GOAL_ACTIONS.getTemplateGoals.type })),
          ),
        ),
      ),
    ),
  );

}