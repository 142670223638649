import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ITemplateGoal } from '../../shared/models/template-goal';
import { TEMPLATE_GOAL_ACTIONS } from './template-goal.actions';


export interface ITemplateGoalState {
  templateGoals: Map<number, ITemplateGoal>;
}

export const initialState: ITemplateGoalState = {templateGoals: new Map<number, ITemplateGoal>()};

export const templateGoalReducer = createReducer(
  initialState,

  on(TEMPLATE_GOAL_ACTIONS.storeTemplateGoals, (state, { payload }) => {
    const templateGoals = new Map(state.templateGoals);
    payload.forEach(goal => {
      // Set the id that the panel will use to toggle the accordion.
      const copiedDimensions = goal.templateDimensions.map(dim => {
        return {
          ...dim,
          dimensionPanelId: `panel-${dim.templateDimensionId}`,
        };
      });
      templateGoals.set(goal.templateGoalId, {
        ...goal,
        templateDimensions: copiedDimensions,
      });
    });
    return {templateGoals};
  }),
);

const getState = createFeatureSelector<ITemplateGoalState>('template-goal');

const getTemplateGoalMapFunc = (state: ITemplateGoalState) => state.templateGoals;
const getTemplateGoalsFunc = (state: ITemplateGoalState) => Array.from(state.templateGoals.values());

const getTemplateGoalMap = createSelector(
  getState,
  getTemplateGoalMapFunc,
);
const getTemplateGoals = createSelector(
  getState,
  getTemplateGoalsFunc,
);

export const TEMPLATE_GOAL_SELECTORS = {
  getTemplateGoalMap,
  getTemplateGoals,
};
