import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ACCOUNT_SELECTORS, IAccountState } from '../../store/account/account.reducer';
import { ICurrentAccountResponse, ICurrentUserAccount, INavigationItem } from '../models/account';
import { UserSettingsHttpService } from './communication/user-settings-http.service';

@Injectable({providedIn: 'root'})
export class UserSettingsService {
  currentAccount$: Observable<ICurrentUserAccount>;
  currentNavigation$: Observable<INavigationItem[]>;

  constructor(
    private userSettingsHttpService: UserSettingsHttpService,
    private store: Store<{ account: IAccountState}>,
  ) {
    this.currentAccount$ = this.store.pipe(select(ACCOUNT_SELECTORS.getCurrentUserAccount));
    this.currentNavigation$ = this.store.pipe(select(ACCOUNT_SELECTORS.getCurrentNavigation));
  }

  getUserAccount(): Observable<ICurrentAccountResponse> {
    return this.userSettingsHttpService.getUserAccount();
  }

  signOut(): void {
    this.userSettingsHttpService.signOut();
  }
}
