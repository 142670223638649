import { createAction, props } from '@ngrx/store';
import { IOrganization } from '../../../shared/models/organization';

const getOrganizations = createAction('PROJECT-getOrganizations');
const storeOrganizations = createAction('PROJECT-storeOrganizations', props<{ payload: IOrganization[] }>());

export const ORGANIZATION_ACTIONS = {
  getOrganizations,
  storeOrganizations,
};
